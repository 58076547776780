<template>
  <CustomModal v-model="showColumnModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{columnConfig != null && columnConfig.id != null ? $t('modal.columnEdit.titleEditColumn') : $t('modal.columnEdit.titleCreateColumn')}}</template>
    <div class="form" v-if="columnConfig != null">
      <div class="row" >
        <div class="label">{{ $t('modal.columnEdit.labelColumnName') }}:</div>
        <div class="value"><input @blur="checkForm" v-model="columnConfig.name" type="text"/>
          <br/><div v-if="errorColumnName != null" class="fieldError">{{errorColumnName}}</div>
        </div>
      </div>
      <div class="row" >
        <div class="label">{{ $t('modal.columnEdit.labelColumnType') }}:</div>
        <div class="value">
          <select @change="checkForm" v-model="columnConfig.type">
            <option :value="fieldTypes['textfield']">{{ $t(`editor.types.textfield`) }}</option>
            <option :value="fieldTypes['dropdown']">{{ $t(`editor.types.dropdown`) }}</option>
            <!--<option :value="fieldTypes['checkbox']">{{ $t(`editor.types.checkbox`) }}</option>-->
          </select>
        </div>
      </div>
      <div class="row" >
        <div class="label">{{ $t('modal.columnEdit.labelColumnWidth') }}:</div>
        <div class="value"><input @blur="checkForm" v-model="columnConfig.width" type="number" min="0" max="100" :disabled="lockWidth"/>
        </div>
      </div>
      <div class="row" v-if="columnConfig.type == fieldTypes['textfield']">
        <div class="label">{{ $t('modal.columnEdit.labelColumnInputType') }}:</div>
        <div class="value">
          <select @change="checkForm" v-model="columnConfig.inputType">
            <option value="any">{{ $t(`editor.inspector.textInput`) }}</option>
            <option value="number">{{ $t(`editor.inspector.numberInput`) }}</option>
          </select>
        </div>
      </div>

      <div class="row" v-if="columnConfig.type == fieldTypes['dropdown']">
        <div class="label">{{ $t('modal.columnEdit.labelColumnDropDownValues') }}:</div>
        <div class="value">
          <div class="valueRow" v-for="(val, idx) in values" :key="idx">
            {{val}}
            <div class="actions">
              <span class="action" @click="editValue(idx)"><i class="fas fa-pen"></i></span>
              <span class="action" @click="removeValue(idx)"><i class="fas fa-trash"></i></span>
              <span class="action" :class="{inactive: idx == 0}" @click="moveValueUp(idx)"><i class="fas fa-angle-up"/></span>
              <span class="action" :class="{inactive: idx == values.length-1}" @click="moveValueDown(idx)">
                <i class="fas fa-angle-down"></i>
              </span>
            </div>
          </div>
          <input type="text" class="newValue" v-model='newValue' :placeholder="$t('editor.inspector.placeholder.newValue')" @keyup.enter="addValue()"/>
          <div class="addButton" @click="addValue()"><i class="fas fa-plus-square"></i></div>
      </div>
    </div>

    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle,no-bitwise */

import CustomModal from '@/components/modals/CustomModal.vue';

import Constants from '@/helpers/Constants';

export default {
  name: 'CreateOrEditColumnModal',
  components: {
    CustomModal,
  },
  data() {
    return {
      columnConfig: null,
      errorColumnName: null,
      callback: null,
      showColumnModal: false,
      fieldTypes: Constants.FIELD_TYPES,
      fieldTypeNames: Constants.FIELD_NAMES,
      values: [],
      newValue: '',
      lockWidth: false,
    };
  },
  methods: {
    show(columnConfig, lockWidth, callback) {
      this.columnConfig = columnConfig;
      this.values = columnConfig.values ?? [];
      this.lockWidth = lockWidth;
      this.callback = callback;
      this.showColumnModal = true;
    },
    hide() {
      this.showColumnModal = false;
    },
    checkForm() {
      this.errorColumnName = null;
      let hasErrors = false;
      if (this.columnConfig == null) return false;

      if (this.columnConfig.name == null || this.columnConfig.name.trim().length === 0) {
        this.errorColumnName = this.$t('validationError.columnNameEmpty');
        hasErrors = true;
      }
      return !hasErrors;
    },
    async confirm() {
      this.columnConfig.values = this.columnConfig.type === this.fieldTypes.dropdown ? this.values : null;
      if (this.callback) this.callback(this.columnConfig);
      this.hide();
    },
    cancel() {
      this.hide();
    },
    addValue() {
      if (this.values == null || this.values.constructor !== Array) this.values = [];
      if (this.newValue.trim().length > 0) {
        this.values.push(this.newValue);
      }
      this.newValue = '';
    },
    async editValue(idx) {
      // eslint-disable-next-line no-alert
      const newValue = window.prompt(this.$t('modal.columnEdit.editValuePrompt'), this.values[idx]);
      if (newValue != null && newValue.trim().length > 0) {
        this.values[idx] = newValue;
      }
    },
    moveValueUp(idx) {
      if (idx > 0) {
        const tmp = this.values[idx - 1];
        this.values[idx - 1] = this.values[idx];
        this.values[idx] = tmp;
      }
    },
    moveValueDown(idx) {
      if (idx < this.values.length - 1) {
        const tmp = this.values[idx + 1];
        this.values[idx + 1] = this.values[idx];
        this.values[idx] = tmp;
      }
    },
    removeValue(idx) {
      this.values.splice(idx, 1);
    },
  },
};
</script>

<style scoped>
.actions {
  flex: 0 0;
  display: flex;
  white-space: nowrap;
}
.action {
  background: var(--color_brown);
  color: white;
  padding: 4px;
  font-size: 0.8em;
  margin-left: 4px;
  cursor: pointer;
  border: none;
  justify-items: flex-end;
  flex: 0 0;
}

.action:hover {
  background: var(--color_orange);
}

.action.inactive {
  background: var(--color_light_brown);
  cursor: default;
}
.valueList {
  max-height: 150px;
  overflow-y: auto;
}
.valueRow {
  display: flex;
  max-width: 100%;
  margin: 8px 0px;
  border-bottom: 0.5px solid #444;
  align-items: center;
  justify-content: space-between;

}

.newValue {
  width: calc(100% - 40px) !important;
}

.addButton {
  float: right;
  font-size: 24px;
  color: var(--color_orange);
}
</style>
