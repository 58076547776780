<template>
  <div class="editor">
    <EditorComponent v-if="configId != null" :configIdToEdit="configId" :isTemplateProp="isTemplate"/>
  </div>
</template>

<script>
// @ is an alias to /src
import EditorComponent from '@/components/editor/EditorComponent.vue';

export default {
  name: 'EditorView',
  components: {
    EditorComponent,
  },
  props: ['configId', 'isTemplate'],
  mounted() {

  },
};
</script>
