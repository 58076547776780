<template>
  <div class="image">
    <canvas ref='imgCanvas'/>
  </div>
</template>

<script>
/* eslint-disable no-console */

export default {
  name: 'ImageViewComponent',
  props: {
    image: {
      type: Object,
      default: null,
    },

  },
  components: {
  },
  data() {
    return {
    };
  },
  watch: {
    image(newVal) {
      this.showImageInCanvas(newVal);
    },
  },
  mounted() {
    if (this.image != null) {
      this.showImageInCanvas(this.image);
    }
  },
  methods: {
    showImageInCanvas(img) {
      const w = 200;
      const h = 200;
      const canvas = this.$refs.imgCanvas;
      const ratio = window.devicePixelRatio;
      let ctx = null;
      canvas.width = w * ratio;
      canvas.height = h * ratio;
      canvas.style.width = `${w}px`;
      canvas.style.height = `${h}px`;
      ctx = canvas.getContext('2d');
      ctx.scale(ratio, ratio);

      if (img != null) {
        const scale = Math.min(w / img.width, h / img.height);
        const imgWidth = img.width * scale;
        const imgHeight = img.height * scale;
        const padX = (w - imgWidth) / 2;
        const padY = (h - imgHeight) / 2;
        // console.log('showImageInCanvas 2', w, h, imgWidth, imgHeight, padX, padY, ctx);
        ctx.drawImage(img, padX, padY, imgWidth, imgHeight);
      }
    },
  },
  computed: {
  },
};
</script>

<style scoped>
</style>
