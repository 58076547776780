/* eslint-disable no-console */
import EditableField from './EditableField';
import Constants from './Constants';

class ListField extends EditableField {
  renderInContext(context, mouseOver) {
    const ctx = context;
    ctx.lineWidth = mouseOver ? 2 : 1;
    ctx.strokeStyle = mouseOver ? '#FF8888' : '#888';
    ctx.fillStyle = '#000';

    const font = `${this.config.fontsize}px ${this.config.fontname}`;

    let fontColor = Constants.convertColor(this.config.fontcolor != null ? this.config.fontcolor : '0#0#0#1');
    if (this.doNotShowOnTablet()) {
      fontColor = Constants.convertAndMergeColor(this.config.fontcolor != null ? this.config.fontcolor : '0#0#0#1', '255#255#255');
    }

    const textMetrics = this.getTextMetrics(ctx, font, '1');
    const lineHeight = textMetrics.descent + textMetrics.textHeight;
    let yPos = 4;
    let i = 1;
    const leftPadding = 4;
    const prefix = this.t != null ? this.t('general.row') : 'Row';
    while (yPos + lineHeight < this.config.height) {
      this.renderTextInBox(
        ctx,
        font,
        `${prefix} ${i}`,
        true,
        fontColor,
        parseInt(this.config.x, 10) + this.fieldPadding + leftPadding,
        parseInt(this.config.y + yPos, 10),
        parseInt(this.config.width, 10) - leftPadding,
        parseInt(lineHeight, 10),
        null,
      );
      ctx.strokeStyle = '#888';
      ctx.lineWidth = 1;
      ctx.beginPath();
      yPos += lineHeight + 2;
      ctx.moveTo(parseInt(this.config.x, 10), parseInt(this.config.y + yPos, 10));
      ctx.lineTo(parseInt(this.config.x + this.config.width, 10), parseInt(this.config.y + yPos, 10));
      ctx.stroke();
      yPos += 2;
      i += 1;
    }

    super.renderBorderInContext(context, mouseOver);
  }
}

export default ListField;
