import Constants from '@/helpers/Constants';
import LanguageHelper from './LanguageHelper';

/* eslint-disable no-console */
class EditableField {
  static OperationType = {
    none: 0,
    move: 1,
    resizeX: 2,
    resizeY: 3,
    resizeXY: 4,
  };

  fieldPadding = 2;

  resizeAreaSize = 10;

  // t is translator module
  constructor(fieldConfig, t = null) {
    this.t = t;
    this.config = fieldConfig;
  }

  doNotShowOnTablet() {
    return this.config.condition != null && this.config.condition.restrictDevice === 'small';
  }

  strokeRoundedRect = (ctx, x, y, w, h, r) => {
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.closePath();
    ctx.stroke();
  }

  renderBorderInContext(context, mouseOver, widthPercent = 1) {
    const ctx = context;
    ctx.lineWidth = mouseOver ? 2 : 1;
    ctx.strokeStyle = mouseOver ? '#FF8888' : '#888';
    ctx.fillStyle = '#000';
    const { x, y } = this.config;
    const w = this.config.width;
    const w2 = this.config.width * widthPercent;
    const h = this.config.height;
    const r = 5;
    this.strokeRoundedRect(ctx, x, y, w, h, r);
    // ctx.strokeRect(this.config.x, this.config.y, this.config.width * widthPercent, this.config.height);
    if (widthPercent < 1) {
      // ctx.strokeRect(this.config.x + this.config.width * widthPercent, this.config.y, this.config.width * (1 - widthPercent), this.config.height);
      ctx.beginPath();
      ctx.moveTo(x + w2, y);
      ctx.lineTo(x + w2, y + h);
      ctx.closePath();
      ctx.stroke();
    }
  }

  calcMatchingFont(context, origFont, origFontSize, lineHeight) {
    let fontSize = origFontSize;
    let font = `${fontSize}px ${origFont}`;
    let tm = this.getTextMetrics(context, font, 'Ög');
    while (fontSize > 2 && tm.textHeight + tm.descent > lineHeight) {
      fontSize -= 0.5;
      font = `${fontSize}px ${this.config.fontname}`;
      tm = this.getTextMetrics(context, font, 'Ög');
    }
    return font;
  }

  getTextMetrics = (context, font, text) => {
    const ctx = context;
    ctx.font = font;
    const textMetrics = ctx.measureText(text);

    // safari
    let textHeight = textMetrics.emHeightAscent + textMetrics.emHeightDescent;
    let descent = textMetrics.emHeightDescent;

    if (Number.isNaN(textHeight)) {
      // chrome
      textHeight = textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent;
      descent = textMetrics.fontBoundingBoxDescent;
    }
    if (Number.isNaN(textHeight)) {
      // firefox
      textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
      descent = textMetrics.actualBoundingBoxDescent;
    }
    return { textHeight, descent, width: textMetrics.width };
  }

  renderTextInBox(context, font, text, ellipsize, color, x, y, width, height) {
    const ctx = context;
    ctx.font = font;
    ctx.fillStyle = color;
    let textToRender = text;
    let addEllipse = false;
    let textMetrics = this.getTextMetrics(ctx, font, textToRender);
    if (ellipsize) {
      while (textMetrics.width > width && textToRender.length > 0) {
        textToRender = `${textToRender.substring(0, textToRender.length - 1)}`;
        textMetrics = this.getTextMetrics(ctx, font, `${textToRender}...`);
        addEllipse = true;
      }
    }
    ctx.fillText(`${textToRender}${addEllipse ? '...' : ''}`, x, y + height / 2 - textMetrics.descent + textMetrics.textHeight / 2, width);
  }

  renderTitleInContext(context, leftPadding = 0, widthPercent = 1, language = LanguageHelper.getBrowserLocale()) {
    const ctx = context;
    const font = `${this.config.fontsize}px ${this.config.fontname}`;

    let fontColor = Constants.convertColor(this.config.fontcolor != null ? this.config.fontcolor : '0#0#0#1');
    if (this.doNotShowOnTablet()) {
      fontColor = Constants.convertAndMergeColor(this.config.fontcolor != null ? this.config.fontcolor : '0#0#0#1', '255#255#255');
    }
    this.renderTextInBox(
      ctx,
      font,
      this.config.title[language] != null ? this.config.title[language] : this.config.title[LanguageHelper.getBrowserLocale()],
      true,
      fontColor,
      parseInt(this.config.x, 10) + this.fieldPadding + leftPadding,
      parseInt(this.config.y, 10),
      parseInt(this.config.width, 10) * parseFloat(widthPercent) - leftPadding,
      parseInt(this.config.height, 10),
      language,
    );
  }

  renderValueInContext(context, exampleValue, leftPadding = 0) {
    const ctx = context;
    const font = `${this.config.valueFontsize}px ${this.config.valueFontname}`;
    ctx.font = font;
    let textMetrics = ctx.measureText(exampleValue);

    // safari
    let textHeight = textMetrics.emHeightAscent + textMetrics.emHeightDescent;
    let descent = textMetrics.emHeightDescent;

    if (Number.isNaN(textHeight)) {
      // chrome
      textHeight = textMetrics.fontBoundingBoxAscent + textMetrics.fontBoundingBoxDescent;
      descent = textMetrics.fontBoundingBoxDescent;
    }
    if (Number.isNaN(textHeight)) {
      // firefox
      textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
      descent = textMetrics.actualBoundingBoxDescent;
    }

    let fontColor = Constants.convertColor(this.config.valueFontcolor != null ? this.config.valueFontcolor : '0#0#0#1');
    if (this.doNotShowOnTablet()) {
      fontColor = Constants.convertAndMergeColor(this.config.valueFontcolor != null ? this.config.valueFontcolor : '0#0#0#1', '255#255#255');
    }
    ctx.fillStyle = fontColor;
    let textToRender = exampleValue;
    let addEllipse = false;
    while (textMetrics.width > this.config.width - leftPadding && textToRender.length > 0) {
      textToRender = `${textToRender.substring(0, textToRender.length - 1)}`;
      textMetrics = ctx.measureText(`${textToRender}...`);
      addEllipse = true;
    }

    ctx.fillText(`${textToRender}${addEllipse ? '...' : ''}`, parseInt(this.config.x, 10) + this.fieldPadding + leftPadding,
      (parseInt(this.config.y, 10) + parseInt(this.config.height, 10) / 2 - descent + textHeight / 2), parseInt(this.config.width, 10) - leftPadding);
  }

  renderCheckBoxInContext(context, boxSize) {
    const ctx = context;
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#000';
    ctx.strokeRect(this.config.x + 4, this.config.y + this.config.height / 2 - boxSize / 2, boxSize, boxSize);
  }

  renderInContext(context, mouseOver, language = LanguageHelper.getBrowserLocale()) {
    let labelPercentage = 1;
    if ([Constants.FIELD_TYPES.textfield, Constants.FIELD_TYPES.textarea, Constants.FIELD_TYPES.dropdown, Constants.FIELD_TYPES.reference].includes(this.config.type)) {
      labelPercentage = this.config.labelWidthPercentage != null ? parseFloat(this.config.labelWidthPercentage, 10) / 100 : 1;
    }
    this.renderBorderInContext(context, mouseOver, labelPercentage);

    let leftTitlePadding = 0;
    if (this.config.type === Constants.FIELD_TYPES.checkbox) {
      leftTitlePadding = 30;
      this.renderCheckBoxInContext(context, 20);
    }

    if (this.config.type === Constants.background) {
      // shape
      try {
        const decoded = Buffer.from(this.config.special, 'base64');
        const parsed = JSON.parse(decoded);
        if (parsed.shape >= 0) {
          const ctx = context;
          if (this.doNotShowOnTablet()) {
            // console.log('color: ', Constants.convertAndMergeColor(parsed.color, '255#255#255'));
          }
          if (parsed.shape === 0) {
            // rectangle
            ctx.fillStyle = Constants.convertColor(parsed.color);
            if (this.doNotShowOnTablet()) {
              ctx.fillStyle = Constants.convertAndMergeColor(parsed.color, '255#255#255');
            }
            ctx.fillRect(this.config.x, this.config.y, this.config.width, this.config.height);
          } else if (parsed.shape === 1) {
            // ellipse
            ctx.fillStyle = Constants.convertColor(parsed.color);
            if (this.doNotShowOnTablet()) {
              ctx.fillStyle = Constants.convertAndMergeColor(parsed.color, '255#255#255');
            }
            ctx.beginPath();
            ctx.ellipse(this.config.x + this.config.width / 2, this.config.y + this.config.height / 2, this.config.width / 2, this.config.height / 2, 0, 0, Math.PI * 2);
            ctx.fill();
          } else if (parsed.shape === 2) {
            // line
            ctx.lineWidth = 2;
            ctx.strokeStyle = Constants.convertColor(parsed.color);
            if (this.doNotShowOnTablet()) {
              ctx.strokeStyle = Constants.convertAndMergeColor(parsed.color, '255#255#255');
            }
            ctx.moveTo(this.config.x, this.config.y + this.config.height / 2);
            ctx.lineTo(this.config.x + this.config.width, this.config.y + this.config.height / 2);
            ctx.stroke();
          }
        } else {
          this.renderTitleInContext(context, leftTitlePadding, labelPercentage, language);
        }
      } catch (e) {
        console.error(e);
        this.renderTitleInContext(context, leftTitlePadding, labelPercentage, language);
      }
    } else {
      this.renderTitleInContext(context, leftTitlePadding, labelPercentage, language);
    }

    if ([Constants.FIELD_TYPES.textfield, Constants.FIELD_TYPES.textarea, Constants.FIELD_TYPES.dropdown].includes(this.config.type)) {
      this.renderValueInContext(context, 'Bespieltext', labelPercentage * parseFloat(this.config.width) + 3);
    } else if (this.config.type === Constants.FIELD_TYPES.reference) {
      this.renderValueInContext(context, 'Beispiel Referenz', labelPercentage * parseFloat(this.config.width) + 3);
    }
  }

  checkMouseOver(mouseInfo) {
    return (mouseInfo != null && mouseInfo.x >= this.config.x && mouseInfo.x <= this.config.x + this.config.width
      && mouseInfo.y >= this.config.y && mouseInfo.y <= this.config.y + this.config.height);
  }

  checkOpType(posInElem) {
    const resizeAreaSize = 10;
    let result = EditableField.OperationType.none;
    if (posInElem.x >= this.config.width - resizeAreaSize && posInElem.y >= this.config.height - resizeAreaSize) {
      result = EditableField.OperationType.resizeXY;
    } else if (posInElem.x >= this.config.width - resizeAreaSize) {
      result = EditableField.OperationType.resizeX;
    } else if (posInElem.y >= this.config.height - resizeAreaSize) {
      result = EditableField.OperationType.resizeY;
    } else {
      result = EditableField.OperationType.move;
    }
    return result;
  }

  startSnapTransform() {
    if (this.transformationOngoing == null) {
      this.tmpX = parseInt(this.config.x, 10);
      this.tmpY = parseInt(this.config.y, 10);
      this.tmpWidth = parseInt(this.config.width, 10);
      this.tmpHeight = parseInt(this.config.height, 10);
    }
  }

  snapField(gridSize, snapPos, snapSizeX, snapSizeY) {
    if (snapPos) {
      this.config.x = Math.round(this.tmpX / gridSize) * gridSize;
      this.config.y = Math.round(this.tmpY / gridSize) * gridSize;
    }
    if (snapSizeX) {
      this.config.width = (Math.round((this.config.x + this.tmpWidth) / gridSize) * gridSize) - this.config.x;
    }
    if (snapSizeY) {
      this.config.height = (Math.round((this.config.y + this.tmpHeight) / gridSize) * gridSize) - this.config.y;
    }
  }

  // auxLines param is null if snap to line not active
  // gridSize param is 0 if snap to line not active
  moveField(deltaX, deltaY, gridSize, auxLines) {
    let snapped = false;
    if (auxLines != null) {
      let newX = parseInt(this.config.x, 10) + deltaX;
      let newY = parseInt(this.config.y, 10) + deltaY;
      for (let i = 0; i < auxLines.length; i += 1) {
        const l = auxLines[i];
        if (l.TYPE === 'H' && Math.abs(newY - l.POS) < 3) {
          newY = l.POS;
          snapped = true;
        } else if (l.TYPE === 'V' && Math.abs(newX - l.POS) < 3) {
          newX = l.POS;
          snapped = true;
        } else if (l.TYPE === 'H' && Math.abs(this.config.height + newY - l.POS) < 3) {
          newY = l.POS - this.config.height;
          snapped = true;
        } else if (l.TYPE === 'V' && Math.abs(this.config.width + newX - l.POS) < 3) {
          newX = l.POS - this.config.width;
          snapped = true;
        }
      }
      if (snapped) {
        this.config.x = newX;
        this.config.y = newY;
      }
    }
    if (!snapped) {
      if (gridSize > 0) {
        this.startSnapTransform();
        this.tmpX += deltaX;
        this.tmpY += deltaY;
        this.snapField(gridSize, true, false, false);
      } else {
        this.config.x = parseInt(this.config.x, 10) + deltaX;
        this.config.y = parseInt(this.config.y, 10) + deltaY;
      }
    }
    this.transformationOngoing = this.moveField;
    return this.transformationOngoing;
  }

  resizeFieldX(deltaX, deltaY, gridSize, auxLines) {
    this.resizeField(deltaX, 0, gridSize, auxLines);
    this.transformationOngoing = this.resizeFieldX;
    return this.transformationOngoing;
  }

  resizeFieldY(deltaX, deltaY, gridSize, auxLines) {
    this.resizeField(0, deltaY, gridSize, auxLines);
    this.transformationOngoing = this.resizeFieldY;
    return this.transformationOngoing;
  }

  resizeField(deltaX, deltaY, gridSize, auxLines) {
    let snapped = false;
    if (auxLines != null) {
      let newWidth = Math.max(10, parseInt(this.config.width, 10) + deltaX);
      let newHeight = Math.max(10, parseInt(this.config.height, 10) + deltaY);

      for (let i = 0; i < auxLines.length; i += 1) {
        const l = auxLines[i];
        if (l.TYPE === 'H' && Math.abs(this.config.y + newHeight - l.POS) < 3) {
          newHeight = l.POS - this.config.y;
          snapped = true;
        } else if (l.TYPE === 'V' && Math.abs(this.config.x + newWidth - l.POS) < 3) {
          newWidth = l.POS - this.config.x;
          snapped = true;
        }
      }
      if (snapped) {
        this.config.width = newWidth;
        this.config.height = newHeight;
      }
    }
    if (!snapped) {
      if (gridSize > 0) {
        this.startSnapTransform();
        this.tmpWidth = Math.max(10, this.tmpWidth + deltaX);
        this.tmpHeight = Math.max(10, this.tmpHeight + deltaY);
        this.snapField(gridSize, false, true, true);
      } else {
        this.config.width = Math.max(10, parseInt(this.config.width, 10) + deltaX);
        this.config.height = Math.max(10, parseInt(this.config.height, 10) + deltaY);
      }
    }
    this.transformationOngoing = this.resizeField;
    return this.transformationOngoing;
  }

  isOnCurrentPage(currentPage) {
    return this.config.page === -1 || this.config.page === currentPage;
  }
}

export default EditableField;
