<template>
  <div class="phoneEditorComponent" id="phoneEditorComponent" ref="phoneEditorComponent" v-if="reportConfig != null" @click="selectField(null, $event)">
    <div class="formWrapper" ref="editorArea" id="phoneEditorFields" >
      <div class="fieldWrapper"
        :class="[{'active': sharedActiveFields != null && sharedActiveFields.indexOf(f) > -1}, {'label': f.config.type == FIELD_TYPES.label}]"
        v-for='f in orderedFields' :key='f.config.name + "_" + updateTimstamp' @click="selectField(f, $event)">

        <!--<div class="type">{{fieldType(f)}}</div>-->

        <div v-if="f.config.type == FIELD_TYPES.label">
          <div class="label"
            :class="{ inactive: doNotShowOnPhone(f) }"
          >{{f.config.title[sharedActiveLanguage]}}</div>
        </div>

        <div v-else-if="f.config.type != FIELD_TYPES.image && f.config.type != FIELD_TYPES.background">
          <div class="title"
            :class="{ inactive: doNotShowOnPhone(f) }"
          >{{f.config.title[sharedActiveLanguage]}} ({{fieldType(f)}})</div>
        </div>

        <div v-if="f.config.type == FIELD_TYPES.textfield">
          <div class="textField"
            :class="{ inactive: doNotShowOnPhone(f) }"
          >{{$t('editor.placeholderEnterText')}}</div>
        </div>

        <div v-if="f.config.type == FIELD_TYPES.textarea">
          <div class="textArea"
            :class="{ inactive: doNotShowOnPhone(f) }"
          >{{$t('editor.placeholderEnterText')}}</div>
        </div>

        <div v-if="f.config.type == FIELD_TYPES.dropdown">
          <div class="dropdown"
            :class="{ inactive: doNotShowOnPhone(f) }"
          >{{$t('editor.placeholderPleaseChoose')}}</div><div class="dropdownIcon"><i class="fas fa-caret-down"/></div>
        </div>

        <div class="checkbox"
          :class="{ inactive: doNotShowOnPhone(f) }"
          v-if="f.config.type == FIELD_TYPES.checkbox">
          <div class="leftIcon black">
            <i class="far fa-square"/>
          </div>
          {{f.config.title[sharedActiveLanguage]}}
        </div>

        <div class="signature"
          :class="{ inactive: doNotShowOnPhone(f) }"
          v-if="f.config.type == FIELD_TYPES.signature">
          <div class="leftIcon">
            <i class="fas fa-signature"/>
          </div>
          {{$t('editor.placeholderTapHere')}}
        </div>

        <div v-if="f.config.type == FIELD_TYPES.action">
          <div class="leftIcon"
            :class="{ inactive: doNotShowOnPhone(f) }">
            <i class="fas fa-image"/>
            <!--
            <i v-if="getActionType(f.config) == ACTION_TYPES.assignPhotos || getActionType(f.config) == ACTION_TYPES.takePhoto" class="fas fa-image"/>
            <i v-if="getActionType(f.config) == ACTION_TYPES.recordVoice" class="fas fa-microphone"/>
            <i v-if="getActionType(f.config) == ACTION_TYPES.locatePosition" class="fas fa-map-pin"/>
            -->
          </div>
          <div class="action"
            :class="{ inactive: doNotShowOnPhone(f) }">{{f.config.title[sharedActiveLanguage]}}</div>
        </div>

        <div class="image"
          :class="{ inactive: doNotShowOnPhone(f) }" v-if="f.config.type == FIELD_TYPES.image" :ref="f.config.name">
          <ImageViewComponent :image="sharedImageDict[f.config.name]" />
        </div>
        <div class="image"
          :class="{ inactive: doNotShowOnPhone(f) }" v-if="f.config.type == FIELD_TYPES.date" :ref="f.config.name">
          <!-- date field -->
          <DateViewComponent :config="f.config" />
        </div>
        <div class="image" :class="{ inactive: doNotShowOnPhone(f) }" v-if="f.config.type == FIELD_TYPES.list" :ref="f.config.name">
          <!-- list field -->
          <ListViewComponent :config="f.config" />
        </div>
        <div class="image" :class="{ inactive: doNotShowOnPhone(f) }" v-if="f.config.type == FIELD_TYPES.table" :ref="f.config.name">
          <!-- table field -->
          <TableViewComponent :config="f.config" />
        </div>
        <div v-if="f.config.type == FIELD_TYPES.reference">
          <div class="label"
            :class="{ inactive: doNotShowOnPhone(f) }"
          >{{f.config.title[sharedActiveLanguage]}}</div>
        </div>
      </div>
    </div>
    <div class="rightSideBar phone" :class="{hidden: sideBarHidden}" v-if="reportConfig != null" @click="$event.stopPropagation()">
      <div class="widget inspector">
        <div class="title" @click="toggleSideBar()"><div class="toggleButton"><i class="fas fa-arrows-alt-h"/></div>{{ $t('editor.inspector.general') }}</div>
        <div class="content">
          <div class="rowLabel">{{ $t('editor.inspector.name') }}:<HelpOverlay :content="$t('editor.inspector.hints.name')"/></div>
          <div class="reportConfigName">{{reportConfig.name}} <span data-cy="inspector-edit-name" v-if="!isTemplate" class="edit" @click="editReportName()">
            <i class="fas fa-pen"></i> </span></div>
          <div class="rowLabel">{{ $t('editor.inspector.languages') }}:
              <button class="dropArrow" @click="limitLanguages = !limitLanguages">
              <div v-if="!limitLanguages"><i class="fas fa-angle-down"/></div>
              <div v-if="limitLanguages"><i class="fas fa-angle-up"/></div>
            </button>
            <HelpOverlay :content="$t('editor.inspector.hints.languages')"/>
          </div>
          <div v-if="limitLanguages">
            <div v-for="(name, code) in languages" :key="code" :class="{'disabled': code == reportConfig.languageFallback}">
              &nbsp;&nbsp;<input type="checkbox" :checked="reportConfig.languages.indexOf(code) > -1" :disabled="isTemplate || code == reportConfig.languageFallback"
              @change="changeLanguage(code, $event); $emit('resetActiveLanguage')" /> {{ name }} ({{ code.toUpperCase() }})
            </div>
            <div class="rowLabel">{{ $t('editor.inspector.fallbackLanguage') }}:<HelpOverlay :content="$t('editor.inspector.hints.fallback')"/></div>
            <div class="select-wrapper">
              <select :disabled="isTemplate" v-model="reportConfig.languageFallback" @change="updateCanvas(); $emit('resetActiveLanguage')">
                <option v-for='(language, idx) in reportConfig.languages' :key="idx" :value="language">{{ languages[language] }} ({{ language.toUpperCase() }})</option>
              </select>
            </div>
          </div>

          <!-- phone only setting-->
          <div class="rowLabel"><input type="checkbox" v-model="reportConfig.phoneOnly"/> {{ $t('editor.inspector.phoneOnly') }}
            <HelpOverlay :content="$t('editor.inspector.hints.phoneOnly')"/>
          </div>

        </div>
      </div>
      <br/>
      <InspectorComponent v-if="reportConfig != null"
        :isTemplate="isTemplate"
        :currentFields="sharedActiveFields"
        :nrOfPages="reportConfig.nrOfPages"
        :reportConfig="reportConfig"
        @updateCanvas="updateCanvas(); storeInUndoStack()"
        @showValueInputPrompt="showValueInputPrompt"
        @showColumnEditDialog="showColumnEditDialog"
        :isTabletMode="false"
      /><br/>
      <!--
      <div class="widget inspector" v-if="reportConfig != null && activeField == null">
        <div class="fieldWrapper" v-for='(f, idx) in reportConfig.fields' :key='f.name'>
          <div class="title">{{idx + 1}}: {{fieldType(f.type)}} - {{f.title}}</div>
        </div>
      </div>
      -->
    </div>
    <PromptModal ref="renameFormModal" :title="$t('modal.titleReportConfigName')" :text="$t('modal.textReportConfigName')"/>
    <PromptModal ref="editValueModal" :title="$t('modal.titleDropDownValueName')" :text="$t('modal.textDropDownValueName')"/>
    <CreateOrEditColumnModal ref="editColumnModal"/>
  </div>
</template>

<script>
/* eslint-disable no-console */

// import Sortable from '@/helpers/Sortable';
import Constants from '@/helpers/Constants';
import ReportHelper from '@/helpers/ReportHelper';
import ReportConfigControllerMixin from '@/mixins/ReportConfigControllerMixin.vue';
import ImageViewComponent from '@/components/editor/mobile/ImageViewComponent.vue';
import DateViewComponent from '@/components/editor/mobile/DateViewComponent.vue';
import ListViewComponent from '@/components/editor/mobile/ListViewComponent.vue';
import TableViewComponent from '@/components/editor/mobile/TableViewComponent.vue';
import InspectorComponent from '@/components/editor/inspectors/InspectorComponent.vue';
import CreateOrEditColumnModal from '@/components/modals/CreateOrEditColumnModal.vue';
import Sortable from 'sortablejs';

export default {
  name: 'PhoneEditorComponent',
  mixins: [ReportConfigControllerMixin],
  props: ['configId', 'loadedReportConfig', 'sharedImageDict', 'sharedUndoStack', 'sharedFields',
    'sharedActiveFields', 'sharedActiveLanguage', 'isTemplate'],
  components: {
    ImageViewComponent,
    DateViewComponent,
    InspectorComponent,
    ListViewComponent,
    TableViewComponent,
    CreateOrEditColumnModal,
  },
  data() {
    return {
      orderedFields: [],
      unwatches: [],
      updateTimstamp: 0,
      FIELD_TYPES: Constants.FIELD_TYPES,
      ACTION_TYPES: Constants.ACTION_TYPES,
      activeFields: [],
      limitLanguages: false,
    };
  },
  watch: {
    sharedActiveFields: {
      handler(newVal) {
        this.activeFields = newVal;
      },
      immediate: true,
      deep: true,
    },
    sharedActiveLanguage: {
      handler() {
        this.updateCanvas();
      },
      deep: true,
    },
    /*
    sharedImageDict: {
      handler(newVal) {
        console.log('normalWatch sharedImageDict', newVal.length);
      },
      deep: true,
    },
    */
  },
  mounted() {
    this.activeFields = this.sharedActiveFields;
    if (this.reportConfig != null && document.getElementById('phoneEditorFields') != null) {
      this.reportConfig = this.loadedReportConfig;
      setTimeout(() => {
        this.update();
      }, 250);
      // this.initSortable();
    }
  },
  unmounted() {
    this.unwatchAll();
  },
  methods: {
    doNotShowOnPhone(f) {
      if (f.config == null || f.config.condition == null) return false;
      return f.config.condition.restrictDevice === 'large';
    },
    updateCanvas() {
      this.update(this.reportConfig);
    },
    update(rc) {
      if (rc == null) return;
      this.reportConfig = rc;
      this.updateTimstamp = new Date().getTime();
      // console.log('update PE', this.updateTimstamp, rc.fields.length);
      this.init();
      this.$forceUpdate();
    },
    notifyActiveFields() {
      this.$emit('update:sharedActiveFields', this.activeFields);
    },
    unwatchAll() {
      this.unwatches.forEach((w) => { w(); });
      this.unwatches.splice(0, this.unwatches.length);
    },
    init() {
      setTimeout(() => {
        this.initImageDropListener(this.$refs.phoneEditorComponent);
        this.initSortable();
      }, 200);
      this.orderedFields = this.getOrderedFieldConfigs4Phone();
      this.unwatchAll();
      this.reportConfig.fields.forEach((f) => {
        if (f.type === Constants.FIELD_TYPES.image) {
          const fieldName = f.name;
          // console.log('add watch', `sharedImageDict.${f.name}`, 'sharedImg', this.sharedImageDict, 'refs', this.$refs);

          if (this.sharedImageDict != null && this.sharedImageDict[f.name] != null && this.$refs[`${fieldName}`] != null) {
            this.$refs[`${fieldName}`].image = this.sharedImageDict[f.name];
          }

          this.unwatches.push(this.$watch(
            `sharedImageDict.${f.name}`,
            (newVal) => {
              // console.log('sharedImageDict', fieldName, this.$refs[`img_${fieldName}`]);
              if (this.$refs[`${fieldName}`] != null) {
                this.$refs[`${fieldName}`].image = newVal;
              }
            },
          ));
        }
      });
    },
    selectField(f, evt) {
      if (evt != null) {
        evt.preventDefault();
        evt.stopPropagation();
      }
      if (f == null) {
        this.activeFields = [];
      } else if (this.activeFields.indexOf(f) === -1 && (evt != null && !evt.shiftKey && !evt.metaKey && !evt.ctrlKey)) {
        this.activeFields = [f];
      } else if (this.activeFields.indexOf(f) === -1) {
        this.activeFields.push(f);
      } else if (evt != null && (evt.shiftKey || evt.metaKey || evt.ctrlKey)) {
        this.activeFields = this.activeFields.filter((a) => a !== f);
      }
      this.notifyActiveFields();
    },
    getActionType(fieldConfig) {
      return ReportHelper.getActionType(fieldConfig);
    },
    initSortable() {
      if (this.isTemplate) return;
      const vm = this;
      Sortable.create(document.getElementById('phoneEditorFields'), {
        draggable: 'div.fieldWrapper',
        ghostClass: 'sort-ghost',
        group: 'fields',
        delay: 100,
        delayOnTouchOnly: true,
        animation: 80,
        onStart: ((evt) => {
          if (!vm.activeFields.includes(vm.orderedFields[evt.oldIndex])) {
            // if a field is dragged that's NOT currently active, deselct all active ones
            vm.selectField(null);
          }
          if (vm.activeFields.length === 0) {
            // if no field is active, set the dragged on to selected
            vm.selectField(vm.orderedFields[evt.oldIndex]);
          }
        }),
        onEnd: (evt) => {
          vm.reorder(evt.oldIndex, evt.newIndex);
        },
      });
    },
    reorder(oldIndex, newIndex) {
      if (oldIndex === newIndex) {
        return;
      }
      const activeFieldsOrdered = [];
      for (let index = this.orderedFields.length - 1; index > -1; index -= 1) {
        if (this.activeFields.indexOf(this.orderedFields[index]) > -1) {
          activeFieldsOrdered.push(this.orderedFields.splice(index, 1)[0]);
        }
      }
      this.orderedFields.splice(newIndex, 0, ...activeFieldsOrdered.reverse());
      let i = 0;
      this.orderedFields.forEach((f) => {
        const field = f;
        field.config.phoneOrderNumber = i;
        i += 1;
      });
      this.storeInUndoStack();
    },
    addNewFieldWithType(typeId) {
      if (typeId === 5) {
        // eslint-disable-next-line no-alert
        alert(this.$t('editor.hintUseDragAndDrop'));
      } else {
        this.addNewFieldConfigWithType(typeId);
      }
      return this.reportConfig;
    },
    async addImageField(source) {
      try {
        // force fix position on tablet
        await this.addImageFieldConfig(source, 200, 200);
        this.updateCanvas(this.reportConfig);
      } catch (error) {
        console.error(error);
      }
    },

  },
};
</script>

<style scoped>
.phoneEditorComponent {
  text-align: left;
  padding: 0px;
  overflow: auto;
  width: 100%;
  min-width: 700px;
  height: calc(100% - 120px);
}

.formWrapper {
  text-align: left;
  width: 400px;
  min-height: 600px;
  overflow-y: auto;
  margin: 20px;
  border: 1px solid black;
  border-radius: 20px;
}

.fieldWrapper, ::v-deep(.fieldWrapper) {
  margin: 0px 0px;
  /*
  border-radius: 4px;
  border: 1px solid gray;
  */
  border: none;
  border-bottom: 1px solid gray;
  padding: 20px;
  cursor: move;
}

.inactive {
  opacity: 0.5;
}

.fieldWrapper.label{
  background: #eeeeee;
  padding: 8px 20px;
}

.fieldWrapper.active {
  background: var(--color_orange)30;
  border: 2px solid var(--color_orange);
}

.fieldWrapper .type, ::v-deep(.fieldWrapper .type) {
  font-size: 0.7em;
}

.sort-ghost, ::v-deep(.sort-ghost) {
  opacity: 0.3;
  transition: all 0.7s ease-out;
}

.fieldWrapper .title {
  color: var(--color_mint);
  font-size: 0.9em;
}

.fieldWrapper .label{
  color: black;
  font-size: 20px;
}

.fieldWrapper .textField {
  margin: 8px 0px;
  color: #aaaaaa;
  font-size: 20px;
}

.fieldWrapper .textArea {
  margin: 8px 0px;
  color: #aaaaaa;
  font-size: 20px;
  vertical-align: text-top;
  height: 100px;
}

.fieldWrapper .dropdown {
  display: inline-block;
  font-size: 20px;
  border: 1px solid #bbbbbb;
  background: #eeeeee;
  margin-top: 8px;
  padding: 4px;
}

.fieldWrapper .dropdownIcon {
  display: inline-block;
  margin-left: 8px;
  color: var(--color_orange);
}

.fieldWrapper .action {
  margin: 8px 0px;
  color: black;
  font-size: 20px;
  display: inline-block;
}

.fieldWrapper .leftIcon {
  margin-right: 20px;
  color: var(--color_orange);
  display: inline-block;
  width: 20px;
  margin-top: 12px;
}

.fieldWrapper .leftIcon.black {
  color: black;
}

.fieldWrapper .checkbox {
  color: black;
  font-size: 20px;
}

div.image {
  text-align: center;
}

div.image img {
  max-height: 100px;
}

::v-deep(.mobileField .row) {
  display: flex;
  border: 1px solid var(--color_light_gray);
  border-bottom: none;
  text-align: left;
  padding: 4px;
}

::v-deep(.mobileField .row .col) {
  flex: 1;
  border: 1px solid var(--color_light_gray);
  padding: 0px 4px;
}

::v-deep(.mobileField .row .col:first-of-type) {
  padding: 0px;
}

::v-deep(.mobileField .row .col:last-of-type) {
  border: none;
}

::v-deep(.mobileField .row:last-of-type) {
  border: 1px solid var(--color_light_gray);
}

.dropArrow {
  border: none;
  background: none !important;
  font-size: 18px;
  color: var(--color_gray) !important;
}

</style>
