<script>
/* eslint-disable no-console */
import Constants from '@/helpers/Constants';
import PromptModal from '@/components/modals/PromptModal.vue';

export default {
  name: 'ReportConfigControllerMixin',
  components: {
    PromptModal,
  },
  data() {
    return {
      reportConfig: null,
      imageDict: null,
      fields: [],
      activeField: null,
      pageSize: { width: 640, height: 940 },
      maxPages: Constants.MAX_PAGES,
      windowHeight: 0,
      dragdropListeners: [],
      sideBarHidden: false,
    };
  },
  mounted() {
  },
  unmounted() {
  },
  watch: {
    loadedReportConfig(newVal) {
      this.reportConfig = newVal;
      if (newVal != null) {
        this.init();
      }
    },
    sharedFields(newVal) {
      this.fields = newVal;
      this.init();
    },
    sharedActiveField(newVal) {
      this.activeField = newVal;
      this.init();
    },
  },
  created() {
    this.imageDict = this.sharedImageDict;
    this.fields = this.sharedFields;
  },
  methods: {
    getReportConfig() {
      return this.reportConfig;
    },
    initImageDropListener(dropElem) {
      /* events fired on the drop targets */
      dropElem.addEventListener('dragover', (e) => {
        // prevent default to allow drop
        e.preventDefault();
      }, false);
      this.dragdropListeners.forEach((l) => {
        dropElem.removeEventListener('drop', l);
      });
      const newListener = (e) => {
        e.preventDefault();
        const { items } = e.dataTransfer;
        for (let idx = 0; idx < items.length; idx += 1) {
          const i = items[idx];
          if (i.type.indexOf('image') !== -1) {
            const blob = i.getAsFile();
            const URLObj = window.URL || window.webkitURL;
            const source = URLObj.createObjectURL(blob);
            this.addImageField(source, e.offsetX, e.offsetY);
          }
        }
      };
      this.dragdropListeners.push(newListener);
      dropElem.addEventListener('drop', newListener);
    },
    fieldType(f) {
      return this.$t(`editor.types.${Constants.FIELD_TYPE_NAMES[f.config != null ? f.config.type : f.type]}`);
    },
    async editReportName() {
      // eslint-disable-next-line no-alert
      const updated = await this.$refs.renameFormModal.show(this.reportConfig.name);
      if (updated != null && updated.trim().length > 0) {
        this.reportConfig.name = updated;
        this.storeInUndoStack();
      }
    },
    getOrderedFieldConfigs4Phone() {
      const sorted = this.fields.slice(0);
      sorted.sort((a, b) => (a.config.phoneOrderNumber != null ? a.config.phoneOrderNumber : 0) - (b.config.phoneOrderNumber != null ? b.config.phoneOrderNumber : 0));
      return sorted;
    },
    addNewField() {
      this.showAddNewFieldChooser = !this.showAddNewFieldChooser;
      const addNewFieldChooser = document.getElementById('addNewFieldChooser');
      addNewFieldChooser.style.width = `${this.addNewFieldChooserSize.width}px`;
      addNewFieldChooser.style.height = 'auto'; // ${this.addNewFieldChooserSize.height}px`;

      const addNewFieldButton = document.getElementById('addNewFieldButton');
      addNewFieldChooser.style.left = `${addNewFieldButton.offsetLeft + (addNewFieldButton.offsetWidth / 2) - (this.addNewFieldChooserSize.width / 2)}px`;
      addNewFieldChooser.style.top = `${addNewFieldButton.offsetTop + (addNewFieldButton.offsetHeight) + 8}px`;
    },
    hideNewFieldChooser() {
      this.showAddNewFieldChooser = false;
    },
    fieldWithPropertyExists(propName, propVal) {
      for (let i = 0; i < this.reportConfig.fields.length; i += 1) {
        if (this.reportConfig.fields[i][propName] === propVal) {
          return true;
        }
      }
      return false;
    },
    getNextFreeName() {
      this.reportConfig.lifetimeFieldCount += 1;
      // search for unused field name
      const newName = `Feld_${Constants.lpad('0', 3, this.reportConfig.lifetimeFieldCount)}`;
      // search for unused field title
      const newTitle = `Titel_${Constants.lpad('0', 3, this.reportConfig.lifetimeFieldCount)}`;
      return { newName, newTitle };
    },
    reOrder4Phone() {
      let i = 0;
      this.getOrderedFieldConfigs4Phone().forEach((f) => {
        const field = f;
        field.config.phoneOrderNumber = i;
        i += 1;
      });
    },
    storeInUndoStack() {
      this.$emit('storeUndoStep');
    },
    addNewFieldConfigWithType(typeId) {
      const { newName, newTitle } = this.getNextFreeName();
      const newConfig = {
        name: newName,
        title: { },
        type: typeId,
        width: 200,
        height: 50,
        page: this.currentPage != null ? this.currentPage : -1,
        x: this.pageSize.width / 2 - 100,
        y: this.pageSize.width / 2 - 25,
        fontcolor: '0#0#0#255',
        fontname: 'Arial',
        fontsize: 16,
        valueFontcolor: '0#0#0#255',
        valueFontname: 'Arial',
        valueFontsize: 16,
        special: btoa('{}'),
        labelWidthPercentage: 50,
        phoneOrderNumber: this.reportConfig.fields.length,
        fieldIdx: this.reportConfig.lifetimeFieldCount,
      };
      newConfig.title[this.reportConfig.languageFallback] = newTitle;
      if (typeId === Constants.FIELD_TYPES.dropdown) {
        // drop down field
        newConfig.special = Buffer.from('{ "values": [] }').toString('base64');
      } else if (typeId === Constants.FIELD_TYPES.date) {
        newConfig.special = Buffer.from(JSON.stringify({
          showTime: true,
          showDate: true,
          timePlaceholder: this.$t('placeholder.time'),
          datePlaceholder: this.$t('placeholder.date'),
        })).toString('base64');
      }
      this.reportConfig.fields.push(newConfig);
      this.reOrder4Phone();
      this.storeInUndoStack();
      this.$emit('addedFieldConfig', newConfig, this.reportConfig);
      return newConfig;
    },
    async showValueInputPrompt(oldVal, callback) {
      const newVal = await this.$refs.editValueModal.show(oldVal);
      callback(newVal);
    },
    async showColumnEditDialog(column, lockWidth, callback) {
      this.$refs.editColumnModal.show(JSON.parse(JSON.stringify(column)), lockWidth, callback);
    },
    async addImageFieldConfig(source, x, y) {
      return new Promise((resolve, reject) => {
        const pastedImage = new Image();
        pastedImage.onload = () => {
          try {
            // this.context.drawImage(pastedImage, 100, 100);
            this.reportConfig.lifetimeFieldCount += 1;
            const newName = Constants.lpad('0', 3, `img_${this.reportConfig.lifetimeFieldCount}`);
            const newConfig = {
              title: [], name: newName, page: this.currentPage != null ? this.currentPage : -1, type: 5, x: x - 100, y: y - 100, width: 200, height: pastedImage.height * (200 / pastedImage.width),
            };
            newConfig.fieldIdx = this.reportConfig.lifetimeFieldCount;
            newConfig.phoneOrderNumber = this.reportConfig.fields.length;

            this.imageDict[`${newName}`] = pastedImage;
            this.reportConfig.fields.push(newConfig);
            this.reOrder4Phone();
            this.storeInUndoStack();
            this.$emit('addedFieldConfig', newConfig, this.reportConfig);
            resolve({ newConfig, image: pastedImage });
          } catch (err) {
            reject(err);
          }
        };
        pastedImage.src = source;
      });
    },
    toggleSideBar() {
      this.sideBarHidden = !this.sideBarHidden;
    },
  },
};
</script>
