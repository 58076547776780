const FIELD_TYPES = {
  label: 0,
  textfield: 1,
  textarea: 2,
  checkbox: 3,
  dropdown: 4,
  image: 5,
  action: 6,
  signature: 7,
  background: 8,
  date: 9,
  reference: 10,
  list: 11,
  table: 12,
};

const FIELD_TYPE_NAMES = [
  'label',
  'textfield',
  'textarea',
  'checkbox',
  'dropdown',
  'image',
  'action',
  'signature',
  'background',
  'date',
  'reference',
  'list',
  'table',
];

const ACTIVE_FIELD_TYPES = [
  FIELD_TYPES.label,
  FIELD_TYPES.textfield,
  FIELD_TYPES.textarea,
  FIELD_TYPES.checkbox,
  FIELD_TYPES.dropdown,
  FIELD_TYPES.date,
  FIELD_TYPES.image,
  FIELD_TYPES.signature,
  FIELD_TYPES.action,
  // FIELD_TYPES.reference,
  FIELD_TYPES.list,
  FIELD_TYPES.table,
];

const ACTION_TYPES = {
  assignPhotos: 0,
  takePhoto: 1,
  recordVoice: 2,
  locatePosition: 3,
};

const ACTION_TYPE_NAMES = [
  'assignPhotos', // 0
  'takePhoto', // 1
  'recordVoice', // 2
  'locatePosition', // 3
  /*
  'scanQRCode', // 4
  'remoteProcessing', // 4
  'loadList', // 6
  */
];

const ACTIVE_ACTION_TYPES = [
  ACTION_TYPES.assignPhotos,
  ACTION_TYPES.recordVoice,
  ACTION_TYPES.locatePosition,
];

const SHAPE_TYPE_NAMES = [
  'rectangle', // 0
  'ellipsis', // 1
  'line', // 2
];

const DATA_BINDING_POSSIBILITIES = [
  'qrcode.N', 'qrcode.FN', 'qrcode.VORNAME', 'qrcode.NACHNAME', 'qrcode.ADR',
  'qrcode.ADDRESS1', 'qrcode.ADDRESS2', 'qrcode.ZIPCODE', 'qrcode.TOWN', 'qrcode.COUNTRY',
  'qrcode.ADR', 'qrcode.TEL', 'qrcode.EMAIL', 'qrcode.TZ', 'qrcode.GEO', 'qrcode.TITLE',
  'qrcode.ROLE', 'qrcode.AGENT', 'qrcode.ORG', 'qrcode.CATEGORIES', 'qrcode.NOTE',
  'qrcode.URL', 'qrcode.NAME', 'qrcode.PROFILE',
];

const REOCCURENCE_TYPES = [
  'once',
  'daily',
  'weekly',
  'every14days',
  'monthly',
  'every4weeks',
  'quarterly',
  'semiYearly',
  'yearly',
];

const MAX_PAGES = 5;
const MAX_UNDO_STEPS = 20;

function convertColor(c) {
  try {
    const parts = c.split('#');
    const col = {
      r: parseInt(parts[0], 10), g: parseInt(parts[1], 10), b: parseInt(parts[2], 10), a: (parseFloat(parts[3], 10) / 255),
    };
    const converted = `rgba(${col.r},${col.g},${col.b},${col.a})`;
    return converted;
  } catch (e) {
    return 'rgba(0, 0, 0, 1)';
  }
}

function convertAndMergeColor(c, o) {
  try {
    const parts = c.split('#');
    const parsed = parts.map((v) => parseInt(v, 10));
    const oparts = o.split('#');
    const oparsed = oparts.map((v) => parseInt(v, 10));
    const col = {
      r: Math.round((Math.abs(parsed[0] - oparsed[0]) / 2)), g: Math.round((Math.abs(parsed[1] - oparsed[1]) / 2)), b: Math.round((Math.abs(parsed[2] - oparsed[2]) / 2)), a: (parsed[3] / 255),
    };
    const converted = `rgba(${col.r},${col.g},${col.b},${col.a})`;
    return converted;
  } catch (e) {
    return 'rgba(0, 0, 0, 1)';
  }
}

function lpad(char, nr, str) {
  let res = `${str}`;
  while (res.length < nr) res = char + res;
  return res;
}

function lpad2(str) {
  return lpad('0', 2, str);
}

function formatDateForBrowserControl(dateIn) {
  const yyyy = dateIn.getFullYear();
  const MM = dateIn.getMonth() + 1; // getMonth() is zero-based
  const dd = dateIn.getDate();
  const HH = dateIn.getHours();
  const mm = dateIn.getMinutes();
  return `${yyyy}-${lpad2(MM)}-${lpad2(dd)}T${lpad2(HH)}:${lpad2(mm)}`;
}

function getFormattedUTCTimeForMySQL(date) {
  const ft = `${date.getUTCFullYear()}-${lpad2(date.getUTCMonth() + 1)}-${lpad2(date.getUTCDate())} ${lpad2(date.getUTCHours())}:${lpad2(date.getUTCMinutes())}:${lpad2(date.getUTCSeconds())}`;
  // eslint-disable-next-line no-console
  // console.log('getFormattedUTCTimeForMySQL', ft);
  return ft;
}

module.exports = {
  FIELD_TYPES,
  FIELD_TYPE_NAMES,
  ACTION_TYPES,
  ACTION_TYPE_NAMES,
  ACTIVE_FIELD_TYPES,
  ACTIVE_ACTION_TYPES,
  DATA_BINDING_POSSIBILITIES,
  MAX_PAGES,
  MAX_UNDO_STEPS,
  SHAPE_TYPE_NAMES,
  REOCCURENCE_TYPES,
  convertColor,
  convertAndMergeColor,
  getFormattedUTCTimeForMySQL,
  lpad,
  lpad2,
  formatDateForBrowserControl,
};
