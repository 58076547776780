<template>
  <div>
    <div class="rowLabel">{{ $t('editor.inspector.checkboxGroup') }}:<HelpOverlay :content="$t('editor.inspector.hints.checkboxGroup')"/></div>
    <input :disabled="isTemplate" type="text" v-model="group" @change="store()" @keyup="store()"/>
  </div>
  <div>
    <div class="rowLabel">
      {{ $t('editor.inspector.checkboxColor') }}:<HelpOverlay :content="$t('editor.inspector.hints.checkboxColor')"/>
    </div>
     <input :disabled="isTemplate" type="text" class="hasPicker" v-model="chartcolor" @change="store();">
    <div v-if="!isTemplate" class="togglePicker" @click="showColorPicker = !showColorPicker; ">
      <div v-if="!showColorPicker"><i class="fas fa-angle-down"></i></div>
      <div v-if="showColorPicker"><i class="fas fa-angle-up"></i></div>
    </div>
    <br/>
    <color-picker
      v-if="showColorPicker"
      :key="checkboxColor"
      theme="light"
      :color="chartcolor"
      :sucker-hide="true"
      @changeColor="colorChanged"
    />
  </div>
</template>

<script>
/* eslint-disable no-console */

import EditableField from '@/helpers/EditableField';
// import Constants from '@/helpers/Constants';
import ColorPicker from '@caohenghu/vue-colorpicker';
import HelpOverlay from '@/components/HelpOverlay.vue';

export default {
  name: 'CheckBoxInspectorComponent',
  props: {
    currentField: {
      type: EditableField,
      default: null,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ColorPicker,
    HelpOverlay,
  },
  data() {
    return {
      showColorPicker: false,
      config: null,
      group: '',
      chartcolor: '',
    };
  },
  watch: {
    currentField(newVal) {
      this.update(newVal);
    },
  },
  mounted() {
    this.update(this.currentField);
  },
  methods: {
    update(newVal) {
      this.config = newVal != null ? newVal.config : null;
      if (this.config != null) {
        try {
          const decoded = Buffer.from(this.config.special, 'base64');
          this.group = JSON.parse(decoded).groupname;
          this.chartcolor = JSON.parse(decoded).chartcolor;
        } catch (e) {
          console.error(e);
        }
      }
    },
    colorChanged(c) {
      this.chartcolor = c.hex;
      this.store();
    },
    store() {
      this.config.special = Buffer.from(JSON.stringify({ groupname: this.group, chartcolor: this.chartcolor })).toString('base64');
    },
  },
  computed: {
  },
};
</script>

<style scoped>

</style>
