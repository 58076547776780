<template>
  <div class="list mobileField">
    <div class="row">{{$t('general.row')}} 1</div>
    <div class="row">{{$t('general.row')}} 2</div>
    <div class="row">{{$t('general.row')}} 3</div>
    <div class="row">{{$t('general.row')}} 4</div>
    <div class="row">...</div>
  </div>
</template>

<script>
/* eslint-disable no-console */

export default {
  name: 'ListViewComponent',
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  components: {
  },
  data() {
    return {
      dateConfig: {},
    };
  },
  watch: {
  },
  mounted() {
    if (this.config != null) this.init(this.config);
  },
  methods: {
    init() {
    },
  },
  computed: {
  },
};
</script>

<style scoped>
.list.mobileField {
  margin-top: 8px;

}
.list.mobileField row {
  margin: 4px 0px;
}
</style>
