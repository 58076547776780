<template>
  <div>
    <div class="select-wrapper">
      <select :disabled="isTemplate" v-model='shape' @change="changeShape()">
        <option value="-1"></option>
        <option v-for="(a, idx) in shapeOptions" :key="idx" :value="idx">{{a}}</option>
      </select>
    </div>
    Color: <input :disabled="isTemplate" type="text" class="hasPicker" v-model="colorConfig" @change="color = convertColor(colorConfig); store(); $emit('updateCanvas')">
    <div v-if="!isTemplate" class="togglePicker" @click="showColorPicker = !showColorPicker; ">
      <div v-if="!showColorPicker"><i class="fas fa-angle-down"></i></div>
      <div v-if="showColorPicker"><i class="fas fa-angle-up"></i></div>
    </div>
    <br/>
    <color-picker
      v-if="showColorPicker"
      :key="config.name"
      theme="light"
      :color="color"
      :sucker-hide="true"
      @changeColor="changeColor"
    />

  </div>
</template>

<script>
/* eslint-disable no-console */

import EditableField from '@/helpers/EditableField';
import Constants from '@/helpers/Constants';
import ColorPicker from '@caohenghu/vue-colorpicker';

export default {
  name: 'BackgroundInspectorComponent',
  props: {
    currentField: {
      type: EditableField,
      default: null,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ColorPicker,
  },
  data() {
    return {
      showColorPicker: false,
      config: null,
      shape: -1,
      colorConfig: '0#0#0#255',
      color: 'rgba(0,0,0,0,0.1)',
      functionName: '',
      shapeOptions: Constants.SHAPE_TYPE_NAMES.map((c) => this.$t(`editor.shapes.${c}`)),
    };
  },
  watch: {
    currentField(newVal) {
      this.update(newVal);
    },
  },
  mounted() {
    this.update(this.currentField);
  },
  methods: {
    changeShape() {
      this.store();
      this.$emit('updateCanvas');
    },
    changeColor(c) {
      this.colorConfig = `${c.rgba.r}#${c.rgba.g}#${c.rgba.b}#${c.rgba.a * 255}`;
      this.store();
      this.$emit('updateCanvas');
    },
    update(newVal) {
      this.config = newVal != null ? newVal.config : null;
      if (this.config != null) {
        const decoded = Buffer.from(this.config.special, 'base64');
        const parsed = JSON.parse(decoded);
        this.shape = parseInt(parsed.shape, 10);
        this.color = Constants.convertColor(parsed.color);
        this.colorConfig = parsed.color;
      }
    },
    store() {
      this.config.special = Buffer.from(JSON.stringify({ shape: this.shape, color: this.colorConfig })).toString('base64');
    },
  },
  computed: {
  },
};
</script>

<style scoped>

</style>
