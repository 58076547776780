/* eslint-disable no-console */
import Constants from '@/helpers/Constants';
import EditableField from './EditableField';

const defaultDateConfig = {
  showTime: false,
  showDate: true,
  showReoccurrences: true,
  showDays: true,
  datePlaceholder: 'Datum',
  timePlaceholder: 'Zeit',
};

class DateField extends EditableField {
  constructor(fieldConfig, t) {
    super(fieldConfig, t);
    const fc = fieldConfig;
    if (fc.special == null) {
      fc.special = btoa(JSON.stringify(defaultDateConfig));
    }
  }

  renderInContext(context, mouseOver) {
    const ctx = context;
    ctx.lineWidth = mouseOver ? 2 : 1;
    ctx.strokeStyle = mouseOver ? '#FF8888' : '#888';
    ctx.fillStyle = '#000';

    let dateConfig = defaultDateConfig;

    try {
      const decoded = Buffer.from(this.config.special, 'base64');
      const parsed = JSON.parse(decoded);
      dateConfig = parsed;
    } catch (e) {
      console.error(e);
    }

    let dateTimePercent = 0.5;

    const x = parseInt(this.config.x, 10);
    const y = parseInt(this.config.y, 10);
    const width = parseInt(this.config.width, 10);
    const height = parseInt(this.config.height, 10);
    const fontColor = Constants.convertColor(this.config.fontcolor != null ? this.config.fontcolor : '0#0#0#1');
    let lineHeight = height;

    if (dateConfig.showDate && dateConfig.showTime) {
      dateTimePercent = 0.5;
    } else if (dateConfig.showDate) {
      dateTimePercent = 1.0;
    } else if (dateConfig.showTime) {
      dateTimePercent = 0;
    }

    if (dateConfig.showDate || dateConfig.showTime) {
      if (dateConfig.showReoccurrences && dateConfig.showDays) {
        lineHeight = height * 0.333;
      } else if (dateConfig.showReoccurrences || dateConfig.showDays) {
        lineHeight = height * 0.5;
      }
    } else if (dateConfig.showReoccurrences && dateConfig.showDays) {
      lineHeight = height * 0.5;
    }

    const font = `${this.config.fontsize}px ${this.config.fontname}`;
    // const font = this.calcMatchingFont(context, this.config.fontname, this.config.fontsize, lineHeight);

    const leftPadding = 4;

    let yPos = y;
    // date box
    if (dateConfig.showDate || dateConfig.showTime) {
      if (dateConfig.showDate) {
        ctx.strokeRect(this.config.x, yPos, this.config.width * dateTimePercent, lineHeight);
        this.renderTextInBox(ctx, font, dateConfig.datePlaceholder, true, fontColor, x + this.fieldPadding + leftPadding, yPos, width * dateTimePercent - leftPadding, lineHeight);
      }
      // time box
      if (dateConfig.showTime) {
        ctx.strokeRect(this.config.x + this.config.width * dateTimePercent, yPos, this.config.width * (1.0 - dateTimePercent), lineHeight);
        this.renderTextInBox(ctx, font, dateConfig.timePlaceholder, true, fontColor,
          x + width * dateTimePercent + this.fieldPadding + leftPadding, yPos, width * (1.0 - dateTimePercent) - leftPadding, lineHeight);
      }
      yPos += lineHeight;
    }

    if (dateConfig.showReoccurrences) {
      ctx.strokeRect(this.config.x, yPos, this.config.width, lineHeight);
      this.renderTextInBox(ctx, font, 'Wiederholung', true, fontColor, x + this.fieldPadding + leftPadding, yPos, width - 2 * leftPadding, lineHeight);
      yPos += lineHeight;
    }

    if (dateConfig.showDays) {
      ctx.strokeRect(this.config.x, yPos, this.config.width, lineHeight);
      this.renderTextInBox(ctx, font, 'Tage', true, fontColor, x + this.fieldPadding + leftPadding, yPos, width - 2 * leftPadding, lineHeight);
    }

    super.renderBorderInContext(context, mouseOver);
  }
}

export default DateField;
