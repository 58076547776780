<template>
  <div class="list mobileField">
    <table class="tableField">
      <thead>
        <tr>
          <th v-for="c in tableConfig.columns" :key="c.id" :style="{width: c.width / 100 * 360+'px', maxWidth: c.width / 100 * 360+'px'}" >{{ c.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="i in 4" :key="i">
          <td v-for="c in tableConfig.columns" :key="c.id" :style="{width: c.width / 100 * 360+'px', maxWidth: c.width / 100 * 360+'px'}" >Beispieltext</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable no-console */

export default {
  name: 'TableViewComponent',
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  components: {
  },
  data() {
    return {
      tableConfig: { columns: [] },
    };
  },
  watch: {
  },
  mounted() {
    if (this.config != null) this.init(this.config);
  },
  methods: {
    init(config) {
      this.tableConfig = JSON.parse(Buffer.from(config.special, 'base64'));
    },
  },
  computed: {
  },
};
</script>

<style scoped>
.list.mobileField {
  margin-top: 8px;
}
.list.mobileField row {
  margin: 4px 0px;
}
.tableField {
  width: 100%;
}

.tableField {
  border: 1px solid var(--color_light_gray);
}
.tableField th, .tableField td {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.tableField th {
  font-weight: 500;
  color: var(--color_orange);
}
</style>
