/* eslint-disable no-console */
import EditableField from './EditableField';

const MAX_IMAGE_SIZE = 1000;

class ImageField extends EditableField {
  constructor(fieldConfig, image) {
    super(fieldConfig);
    this.image = image;
  }

  renderInContext(context, mouseOver) {
    if (this.image != null) {
      const scale = Math.min(this.config.width / this.image.width, this.config.height / this.image.height);
      const imgWidth = this.image.width * scale;
      const imgHeight = this.image.height * scale;
      const padX = (this.config.width - imgWidth) / 2;
      const padY = (this.config.height - imgHeight) / 2;
      context.drawImage(this.image, parseInt(this.config.x, 10) + padX, parseInt(this.config.y, 10) + padY, imgWidth, imgHeight);
    }
    super.renderBorderInContext(context, mouseOver);
  }

  setImage(img, context = null) {
    this.image = img;
    if (context != null) {
      this.renderBorderInContext(context, false);
    }
  }

  static getBase64Data(image) {
    const imageConverter = document.getElementById('imageConverterCanvas');
    let targetWidth = image.width;
    let targetHeight = image.height;
    // console.log('getBase64Data', targetWidth, targetHeight);
    if (targetHeight > MAX_IMAGE_SIZE || targetWidth > MAX_IMAGE_SIZE) {
      const scale = MAX_IMAGE_SIZE / (image.width > image.height ? image.width : image.height);
      targetWidth = image.width * scale;
      targetHeight = image.height * scale;
    }
    const ratio = window.devicePixelRatio;
    let ctx = null;

    imageConverter.width = targetWidth * ratio;
    imageConverter.height = targetHeight * ratio;
    imageConverter.style.width = `${targetWidth}px`;
    imageConverter.style.height = `${targetHeight}px`;
    ctx = imageConverter.getContext('2d');
    ctx.scale(ratio, ratio);

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, targetWidth, targetHeight);
    ctx.drawImage(image, 0, 0, targetWidth, targetHeight);
    // console.log('getBase64Data TARGET', targetWidth, targetHeight);
    const i = image;
    i.crossOrigin = 'anonymous';
    let imageData = imageConverter.toDataURL('image/jpeg', 0.75);
    imageData = imageData.replace('data:image/jpeg;base64,', '');
    return imageData;
  }
}

export default ImageField;
