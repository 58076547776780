<template>
  <div v-if="config != null" class="inspector">
    <div class="title">{{ $t('editor.inspector.inspector') }}</div>

    <div class="content">
      <!-- name -->
      <div class="rowLabel">{{ $t('editor.inspector.type') }}:<HelpOverlay :content="$t('editor.inspector.hints.type')"/></div>
        <input type="text" class="disabled" v-model="fieldType" disabled="true"><br/>

      <!-- Name: <input type="text" v-model="config.name" @change="$emit('updateCanvas')"  @keyup="$emit('updateCanvas')"><br/> -->
      <div v-if="!isTitleIndependent">
        <div class="rowLabel">
          {{ $t('editor.inspector.title') }}:&nbsp;&nbsp;<button class="dropArrow" @click="changeTitleIndependence(true)">
          <i class="fas fa-angle-down"/>
          </button>
          <HelpOverlay :content="$t('editor.inspector.hints.title')"/>
        </div>
      <input type="text" :disabled="isTemplate" v-model="config.title[reportConfig.languageFallback]" @change="$emit('updateCanvas')" @keyup="$emit('updateCanvas')"><br/>
      </div>
      <div v-if="isTitleIndependent">
        <div class="rowLabel">
          {{ $t('editor.inspector.titles') }}:&nbsp;<button class="dropArrow" @click="changeTitleIndependence(false)">
            <i class="fas fa-angle-up"/>
          </button>
          <HelpOverlay :content="$t('editor.inspector.hints.title')"/>
        </div>
        <div v-for="(language, idx) in reportConfig.languages" :key="idx">
          <div class="shortLabel">{{ language.toUpperCase() }}: </div><input :disabled="isTemplate" type="text" class="short"
          :value="config.title[language] != null ? config.title[language] : config.title[reportConfig.languageFallback]"
           @change="changeTitle(language, $event)" @keyup="changeTitle(language, $event)"><br/>
        </div>
      </div>
      <!-- is title field -->
      <div class="rowLabel">
        <input :disabled="isTemplate" type="checkbox" v-model="isTitleField" @change="config.isTitleField = isTitleField ? 1 : 0; $emit('updateCanvas')"/>&nbsp;
        {{$t('editor.inspector.isTitleField') }}
        <HelpOverlay :content="$t('editor.inspector.hints.isTitleField')"/>
      </div>

      <!-- is mandatory -->
      <div class="rowLabel" v-if="![FIELD_TYPES.label, FIELD_TYPES.image, FIELD_TYPES.background].includes(config.type)">
        <input :disabled="isTemplate" type="checkbox" v-model="isMandatory" @change="config.isMandatory = isMandatory ? 1 : 0; $emit('updateCanvas')" />&nbsp;
        {{$t('editor.inspector.isMandatory') }}
        <HelpOverlay :content="$t('editor.inspector.hints.isMandatory')"/>
      </div>
      <div class="rowLabel">{{ $t('editor.page') }}:<HelpOverlay :content="$t('editor.inspector.hints.page')"/></div>
      <div class="select-wrapper">
        <select :disabled="isTemplate" v-model="config.page" @change="$emit('updateCanvas')">
          <option :key="-1" :value="parseInt(-1)">{{ $t('editor.inspector.allPages') }}</option>
          <option v-for='pageNr in nrOfPages' :key="pageNr" :value="(pageNr - 1)">{{ $t('editor.page') }} {{pageNr}}</option>
        </select><br/>
      </div>
    </div>

    <!-- geometry (position and size) -->
    <div v-if="isTabletMode"  class="title">{{ $t('editor.inspector.position') }}</div>
    <div v-if="isTabletMode"  class="content">
      <div class="rowLabel">{{ $t('editor.inspector.posX') }}:<HelpOverlay :content="$t('editor.inspector.hints.posX')"/></div>
      <input :disabled="isTemplate" type="number" v-model="config.x" @change="sanitizeAndUpdateCanvas"><br/>
      <div class="rowLabel">{{ $t('editor.inspector.posY') }}:<HelpOverlay :content="$t('editor.inspector.hints.posY')"/></div><input :disabled="isTemplate" type="number" v-model="config.y" @change="sanitizeAndUpdateCanvas"><br/>
      <div class="rowLabel">{{ $t('editor.inspector.width') }}:<HelpOverlay :content="$t('editor.inspector.hints.width')"/></div><input :disabled="isTemplate" type="number" v-model="config.width" @change="sanitizeAndUpdateCanvas"><br/>
      <div class="rowLabel">{{ $t('editor.inspector.height') }}:<HelpOverlay :content="$t('editor.inspector.hints.height')"/></div><input :disabled="isTemplate" type="number" v-model="config.height" @change="sanitizeAndUpdateCanvas"><br/>
      <div v-if="[FIELD_TYPES.textfield, FIELD_TYPES.textarea, FIELD_TYPES.dropdown].includes(config.type)">
        <div class="rowLabel">{{ $t('editor.inspector.titleWidth') }}:<HelpOverlay :content="$t('editor.inspector.hints.titleWidth')"/> {{config.labelWidthPercentage}} %</div>
        <input :disabled="isTemplate" class="hasPicker" type="range" min="0" max = "100" v-model="config.labelWidthPercentage" @change="sanitizeAndUpdateCanvas"> <br/>
      </div>

      <!-- data binding section -->
      <!--
      <div v-if="[FIELD_TYPES.textfield, FIELD_TYPES.textarea].includes(config.type)">
        <div class="sectionTitle">{{ $t('editor.inspector.dataBinding') }}</div>
        <div class="select-wrapper">
          <select v-model='config.binding'>
            <option value=""></option>
            <option v-for="(poss, idx) in dataBindingPossibilities" :key="idx" :value="poss">{{poss}}</option>
          </select><br/>
        </div>
      </div>
      -->
    </div>

    <div v-if="reportConfig != null" class="title">{{ $t('editor.inspector.displayCondition') }}</div>
    <div v-if="reportConfig != null" class="content">
      <!-- conditional display -->
      <div>
        <div class="rowLabel">{{ $t('editor.inspector.field') }}:<HelpOverlay :content="$t('editor.inspector.hints.field')"/></div>
        <div class="select-wrapper">
          <select :disabled="isTemplate" v-model='config.condition.fieldName' ref="conditionField" @change="setConditionField">
            <option value=""></option>
            <option v-for="(poss, idx) in conditionalRenderingOptions" :key="idx" :value="poss.name">{{poss.title[reportConfig.languageFallback]}}</option>
          </select><br/>
        </div>
        <div v-if="config.condition.fieldType >= 0">
          <div class="rowLabel">{{ $t('editor.inspector.hasToBe') }}:<HelpOverlay :content="$t('editor.inspector.hints.hasToBe')"/></div>
          <input :disabled="isTemplate" v-if="config.condition.fieldType == 3" type="checkbox" v-model="config.condition.value"/>
          <div class="select-wrapper" v-if="config.condition.fieldType == 4">
            <select :disabled="isTemplate" v-model="config.condition.value">
            <option v-for="(opt, idx) in conditionOptions" :key="idx">{{opt}}</option>
            </select>
          </div>
        </div>
        <br/>
        <div class="rowLabel">{{ $t('editor.inspector.restrictDevice') }}:<HelpOverlay :content="$t('editor.inspector.hints.restrictDevice')"/></div>
        <div class="select-wrapper">
          <select :disabled="isTemplate" v-model="config.condition.restrictDevice" @change="$emit('updateCanvas')">
            <option :value="'all'">{{ $t('editor.inspector.allDevices') }}</option>
            <option :value="'large'">{{ $t('editor.inspector.onlyLarge') }}</option>
            <option :value="'small'">{{ $t('editor.inspector.onlySmall') }}</option>
          </select><br/>
        </div>
      </div>
    </div>
    <div v-if="isTabletMode" class="title">{{ $t('editor.inspector.font') }}</div>
    <div v-if="isTabletMode" class="content">
      <!-- title font section -->
      <div class="sectionTitle">Titel</div>
      <div class="rowLabel">{{ $t('editor.inspector.font') }}:<HelpOverlay :content="$t('editor.inspector.hints.font')"/></div> <input :disabled="isTemplate" type="text" v-model="config.fontname" @change="$emit('updateCanvas')" @keyup="$emit('updateCanvas')"><br/>
      <div class="rowLabel">{{ $t('editor.inspector.fontSize') }}:<HelpOverlay :content="$t('editor.inspector.hints.fontSize')"/></div> <input :disabled="isTemplate" type="number" v-model="config.fontsize" @change="$emit('updateCanvas')"><br/>
      <div class="rowLabel">{{ $t('editor.inspector.fontColor') }}:<HelpOverlay :content="$t('editor.inspector.hints.fontColor')"/></div> <input :disabled="isTemplate" class="hasPicker" type="text" v-model="config.fontcolor"
        @change="fontColor = convertColor(config.fontcolor); $emit('updateCanvas')">
      <div class="togglePicker" @click="if (!isTemplate) { showFontColorPicker = !showFontColorPicker; showValueFontColorPicker = false; }">
        <div v-if="!showFontColorPicker"><i class="fas fa-angle-down"></i></div>
        <div v-if="showFontColorPicker"><i class="fas fa-angle-up"></i></div>
      </div>
      <br/>
      <color-picker
        v-if="showFontColorPicker"
        :key="config.name"
        theme="light"
        :color="fontColor"
        :sucker-hide="true"
        @changeColor="changeFontColor"
      />
      <div v-if="isTabletMode && [FIELD_TYPES.textfield, FIELD_TYPES.textarea, FIELD_TYPES.dropdown].includes(config.type)">
        <div class="sectionTitle">Wert</div>
        <div class="rowLabel">{{ $t('editor.inspector.font') }}:<HelpOverlay :content="$t('editor.inspector.hints.valueFont')"/></div> <input :disabled="isTemplate" type="text" v-model="config.valueFontname" @change="$emit('updateCanvas')" @keyup="$emit('updateCanvas')"><br/>
        <div class="rowLabel">{{ $t('editor.inspector.fontSize') }}:<HelpOverlay :content="$t('editor.inspector.hints.valueFontSize')"/></div> <input :disabled="isTemplate" type="number" v-model="config.valueFontsize" @change="$emit('updateCanvas')"><br/>
        <div class="rowLabel">{{ $t('editor.inspector.fontColor') }}:<HelpOverlay :content="$t('editor.inspector.hints.valueFontColor')"/></div> <input :disabled="isTemplate" type="text" class="hasPicker" v-model="config.valueFontcolor"
          @change="valueFontColor = convertColor(config.valueFontcolor); $emit('updateCanvas')">
        <div class="togglePicker" @click="if (!isTemplate) { showValueFontColorPicker = !showValueFontColorPicker; showFontColorPicker = false }">
          <div v-if="!showValueFontColorPicker"><i class="fas fa-angle-down"></i></div>
          <div v-if="showValueFontColorPicker"><i class="fas fa-angle-up"></i></div>
        </div>
        <br/>
        <color-picker
          v-if="showValueFontColorPicker"
          :key="config.name"
          theme="light"
          :color="valueFontColor"
          :sucker-hide="true"
          @changeColor="changeValueFontColor"
        />
      </div>
    </div>

    <div v-if="currentFields != null && currentFields.length === 1
      && [FIELD_TYPES.checkbox, FIELD_TYPES.textfield, FIELD_TYPES.dropdown,
        FIELD_TYPES.action, FIELD_TYPES.background, FIELD_TYPES.date, FIELD_TYPES.table].includes(config.type)">
      <div class="title">{{fieldType}}</div>
        <div class="content">
          <DropDownInspectorComponent
            :isTemplate="isTemplate"
            :key="config.name"
            v-if="config.type == FIELD_TYPES.dropdown"
            ref="DropDownInspectorComponent"
            :currentField="currentFields[0]"
            @showValueInputPrompt="showValueInputPrompt"
          />
          <TextFieldInspectorComponent
            :isTemplate="isTemplate"
            :key="config.name"
            ref="TextFieldInspectorComponent"
            v-if="config.type == FIELD_TYPES.textfield"
            :currentField="currentFields[0]"
          />
          <CheckBoxInspectorComponent
            :isTemplate="isTemplate"
            :key="config.name"
            ref="CheckBoxInspectorComponent"
            v-if="config.type == FIELD_TYPES.checkbox"
            :currentField="currentFields[0]"
          />
          <ActionInspectorComponent
            :isTemplate="isTemplate"
            :key="config.name"
            ref="ActionInspectorComponent"
            v-if="config.type == FIELD_TYPES.action"
            :currentField="currentFields[0]"
          />
          <BackgroundInspectorComponent
            :isTemplate="isTemplate"
            :key="config.name"
            ref="BackgroundInspectorComponent"
            v-if="config.type == FIELD_TYPES.background"
            :currentField="currentFields[0]"
            @updateCanvas="$emit('updateCanvas')"
          />

          <DateInspectorComponent
            :isTemplate="isTemplate"
            :key="config.name"
            ref="DateInspectorComponent"
            v-if="config.type == FIELD_TYPES.date"
            :currentField="currentFields[0]"
            @updateCanvas="$emit('updateCanvas')"
          />
          <TableInspectorComponent
            :isTemplate="isTemplate"
            :key="config.name"
            v-if="config.type == FIELD_TYPES.table"
            ref="TableInspectorComponent"
            :currentField="currentFields[0]"
            @updateCanvas="$emit('updateCanvas')"
            @showColumnEditDialog="showColumnEditDialog"
          />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import Constants from '@/helpers/Constants';
import ColorPicker from '@caohenghu/vue-colorpicker';
import DropDownInspectorComponent from '@/components/editor/inspectors/DropDownInspectorComponent.vue';
import CheckBoxInspectorComponent from '@/components/editor/inspectors/CheckBoxInspectorComponent.vue';
import ActionInspectorComponent from '@/components/editor/inspectors/ActionInspectorComponent.vue';
import BackgroundInspectorComponent from '@/components/editor/inspectors/BackgroundInspectorComponent.vue';
import DateInspectorComponent from '@/components/editor/inspectors/DateInspectorComponent.vue';
import TextFieldInspectorComponent from '@/components/editor/inspectors/TextFieldInspectorComponent.vue';
import TableInspectorComponent from '@/components/editor/inspectors/TableInspectorComponent.vue';
import HelpOverlay from '@/components/HelpOverlay.vue';

export default {
  name: 'InspectorComponent',
  props: {
    currentFields: {
      type: Array,
      default: null,
    },
    nrOfPages: {
      type: Number,
      default: 1,
    },
    reportConfig: {
      type: Object,
      default: null,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
    isTabletMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ColorPicker,
    DropDownInspectorComponent,
    CheckBoxInspectorComponent,
    ActionInspectorComponent,
    BackgroundInspectorComponent,
    DateInspectorComponent,
    TableInspectorComponent,
    TextFieldInspectorComponent,
    HelpOverlay,
  },
  data() {
    return {
      config: null,
      fontColor: Constants.convertColor('0#0#0#255'),
      valueFontColor: Constants.convertColor('0#0#0#255'),
      dontShowPhone: false,
      isTitleField: false,
      isMandatory: false,
      showFontColorPicker: false,
      showValueFontColorPicker: false,
      dataBindingPossibilities: Constants.DATA_BINDING_POSSIBILITIES,
      conditionOptions: [],
      FIELD_TYPES: Constants.FIELD_TYPES,
    };
  },
  watch: {
    currentFields: {
      async handler(newVal) {
        const newConfig = newVal != null && newVal.length === 1 ? newVal[0].config : null;
        this.config = newConfig;
        if (this.config != null) {
          this.fontColor = Constants.convertColor(this.config.fontcolor);
          this.isTitleField = this.config.isTitleField === 1;
          this.isMandatory = this.config.isMandatory === 1;
          if (this.config.condition == null) this.config.condition = {};
          if (this.config.condition.restrictDevice == null) this.config.condition.restrictDevice = 'all';
          this.setConditionField();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.config != null) {
      this.isTitleField = this.config.isTitleField === 1;
      this.isMandatory = this.config.isMandatory === 1;
      if (this.config.condition == null) {
        this.config.condition = {};
      } else {
        this.setConditionField();
      }
    }
  },
  methods: {
    changeTitleIndependence(independence) {
      if (this.isTemplate) return;
      if (this.isTitleIndependent && !independence) {
        const save = this.config.title[this.reportConfig.languageFallback];
        this.config.title = { };
        this.config.title[this.reportConfig.languageFallback] = save != null ? save : '';
        this.$emit('updateCanvas');
      } else if (!this.isTitleIndependent && independence) {
        this.reportConfig.languages.forEach((language) => {
          this.config.title[language] = this.config.title[language] != null ? this.config.title[language] : this.config.title[this.reportConfig.languageFallback];
        });
        this.$emit('updateCanvas');
      }
    },
    changeTitle(key, event) {
      if (this.isTemplate) return;
      this.config.title[key] = event.target.value;
      this.$emit('updateCanvas');
    },
    showValueInputPrompt(oldValue, callback) {
      if (this.isTemplate) return;
      this.$emit('showValueInputPrompt', oldValue, callback);
    },
    showColumnEditDialog(column, lockWidth, callback) {
      if (this.isTemplate) return;
      this.$emit('showColumnEditDialog', column, lockWidth, callback);
    },
    sanitizeAndUpdateCanvas() {
      if (this.isTemplate) return;
      this.config.x = parseInt(this.config.x, 10);
      this.config.y = parseInt(this.config.y, 10);
      this.config.width = parseInt(this.config.width, 10);
      this.config.height = parseInt(this.config.height, 10);
      this.$emit('updateCanvas');
    },
    convertColor(c) {
      return Constants.convertColor(c);
    },
    changeFontColor(c) {
      this.config.fontcolor = `${c.rgba.r}#${c.rgba.g}#${c.rgba.b}#${c.rgba.a * 255}`;
      this.$emit('updateCanvas');
    },
    changeValueFontColor(c) {
      if (this.isTemplate) return;
      this.config.valueFontcolor = `${c.rgba.r}#${c.rgba.g}#${c.rgba.b}#${c.rgba.a * 255}`;
      this.$emit('updateCanvas');
    },
    setConditionField() {
      if (this.lastSeenConditionFieldName === this.config.condition.fieldName) return;
      this.lastSeenConditionFieldName = this.config.condition.fieldName;
      this.config.condition.fieldType = -1;
      this.conditionOptions = [];
      this.reportConfig.fields.forEach((f) => {
        if (f.name === this.config.condition.fieldName) {
          this.config.condition.fieldType = f.type;
          this.config.condition.fieldTitle = f.title;
          if (f.type === Constants.FIELD_TYPES.dropdown) {
            // drop down
            const decoded = Buffer.from(f.special, 'base64');
            this.conditionOptions = ['', ...JSON.parse(decoded).values];
          }
        }
      });
    },
  },
  computed: {
    fieldType() {
      return this.$t(`editor.types.${Constants.FIELD_TYPE_NAMES[this.config.type]}`);
    },
    conditionalRenderingOptions() {
      const fieldNames = [];
      this.reportConfig.fields.forEach((f) => {
        if ([Constants.FIELD_TYPES.checkbox, Constants.FIELD_TYPES.dropdown].includes(f.type) && this.config.name !== f.name) {
          fieldNames.push(f);
        }
      });
      return fieldNames;
    },
    isTitleIndependent() {
      let independent = false;
      this.reportConfig.languages.forEach((language) => {
        if (language !== this.reportConfig.languageFallback && this.config.title[language] != null) {
          independent = true;
        }
      });
      return independent;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.sectionTitle, ::v-deep(.sectionTitle) {
  font-weight: 800;
  margin: 8px 0px;
}
.togglePicker, ::v-deep(.togglePicker) {
  float: right;
  background: var(--color_orange);
  color: white;
  width: 10px;
  height: 20px;
  padding: 5px 10px;
  font-size: 1.0em;
  margin-left: 4px;
  cursor: pointer;
  margin-right: 2px;
  margin-top: 0px;
  border-radius: 5px;
}

.addButton, ::v-deep(.addButton) {
  float: right;
  color: var(--color_orange);
  padding: 0px;
  font-size: 26px;
  margin-left: 4px;
  cursor: pointer;
  margin-right: 4px;
  margin-top: 0px;
}

.shortLabel {
  display: inline-block;
  width: 30px !important;
}
.short {
  width: calc(100% - 42px) !important;
}

.dropArrow {
  border: none;
  background: none !important;
  font-size: 18px;
  color: var(--color_gray) !important;
}

</style>
