<template>
  <div>
    <div class="toolbar">
      <!-- convert template to config -->
      <div v-if="isTemplate" class="action" id="cloneTemplate" @click="$emit('cloneTemplate')">
        <i class="fas fa-plus-circle"></i> <span class="actionLabel">{{ $t('editor.toolbar.cloneTemplate') }}</span>
      </div>
      <div v-if="!isTemplate" class="action" data-cy='editor-add' id="addNewFieldButton" @click="addNewField()">
        <i class="fas fa-plus-square"></i> <span class="actionLabel">{{ $t('editor.toolbar.addField') }}</span></div>
      <div v-if="!isTemplate" class="action" data-cy='editor-duplicate' @click="$emit('duplicateField')">
        <i class="fas fa-copy"></i> <span class="actionLabel">{{ $t('editor.toolbar.duplicate') }}</span></div>
      <div v-if="!isTemplate" class="action" data-cy='editor-delete' @click="$emit('deleteField')">
        <i class="fas fa-trash-alt"></i> <span class="actionLabel">{{ $t('editor.toolbar.delete') }}</span></div>
      <!-- tablet specific -->
      <div v-if="!isTemplate && currentEditor == 0" class="action" @click="$emit('addVerticalAuxLine')">
        <i class="fas fa-arrows-alt-v"></i> <span class="actionLabel">{{ $t('editor.toolbar.addVertical') }}</span></div>
      <div v-if="!isTemplate && currentEditor == 0" class="action" @click="$emit('addHorizontalAuxLine')">
        <i class="fas fa-arrows-alt-h"></i> <span class="actionLabel">{{ $t('editor.toolbar.addHorizontal') }}</span></div>
      <div v-if="!isTemplate && currentEditor == 0" class="action" @click="$emit('moveForwards')">
        <i class="fas fa-layer-group"></i><i class="fas fa-long-arrow-alt-up"></i> <span class="actionLabel">{{ $t('editor.toolbar.front') }}</span></div>
      <div v-if="!isTemplate && currentEditor == 0" class="action" @click="$emit('moveBackwards')"
      ><i class="fas fa-layer-group"></i><i class="fas fa-long-arrow-alt-down"></i> <span class="actionLabel">{{ $t('editor.toolbar.back') }}</span></div>
      <span v-else>
        <!-- phone specific -->
      </span>
    </div>
    <div id="addNewFieldChooser" class="addNewFieldChooser" v-show="showAddNewFieldChooser" @mouseleave="hideNewFieldChooser">
      <div class="fieldType" v-for='ft in fieldTypes' :key='ft' @click='!fieldTypeDisables[ft] ? addNewFieldWithType(ft) : {}' :disabled="fieldTypeDisables[ft]">
        {{ $t(`editor.types.${fieldTypeNames[ft]}`) }}
        <help-overlay placement="right" :content="$t(`editor.types.hints.${fieldTypeNames[ft]}`)"/>
      </div>
      <div class="fieldType cancel" @click='hideNewFieldChooser()'>{{ $t('general.cancel') }}</div>
    </div>

  </div>
</template>
<script>
/* eslint-disable no-console */
import Constants from '@/helpers/Constants';
import HelpOverlay from './HelpOverlay.vue';

export default {
  name: 'ToolbarComponent',
  components: {
    HelpOverlay,
  },
  props: ['sharedUndoStack', 'currentEditor', 'isTemplate', 'fieldTypeDisables'],
  data() {
    return {
      fieldTypes: Constants.ACTIVE_FIELD_TYPES,
      fieldTypeNames: Constants.FIELD_TYPE_NAMES,
      showAddNewFieldChooser: false,
      addNewFieldChooserSize: { width: 150, height: 310 },
      undoStack: null,
    };
  },
  created() {
    this.undoStack = this.sharedUndoStack;
  },
  watch: {
  },
  methods: {
    addNewField() {
      this.showAddNewFieldChooser = true;
      const addNewFieldChooser = document.getElementById('addNewFieldChooser');
      addNewFieldChooser.style.width = `${this.addNewFieldChooserSize.width}px`;
      addNewFieldChooser.style.height = 'auto'; // ${this.addNewFieldChooserSize.height}px`;

      const addNewFieldButton = document.getElementById('addNewFieldButton');
      addNewFieldChooser.style.left = `${addNewFieldButton.offsetLeft + (addNewFieldButton.offsetWidth / 2) - (this.addNewFieldChooserSize.width / 2)}px`;
      addNewFieldChooser.style.top = `${addNewFieldButton.offsetTop + (addNewFieldButton.offsetHeight) + 8}px`;
    },
    hideNewFieldChooser() {
      this.showAddNewFieldChooser = false;
    },
    addNewFieldWithType(typeId) {
      this.showAddNewFieldChooser = false;
      this.$emit('addNewFieldWithType', typeId);
    },
  },
};
</script>
<style scoped>
.toolbar {
  text-align: left;
  margin: 0px 0px;
  background-color: var(--color_green);
  color: white;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  padding: 14px 10px;
  min-width: 500px;
  overflow-x: auto;
  height: 38px;
}

.toolbar .action {
  cursor: pointer;
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  padding: 2px 15px 0px;
  border-radius: 8px;
  text-align: center;
}

.toolbar .action:hover {
  background: white;
  color: var(--color_green);
  box-shadow: 1px 1px 2px rgba(0,0,0, 0.3);
}

.addNewFieldChooser {
  position: absolute;
  border-radius: 0px;
  border: 1px solid white;
  background-color: #3ba67aee;
  overflow-y: auto;
  box-shadow: 2px 1px 20px #888;
  z-index: 99;
  min-width: 200px;
  margin-top: 8px;
  color: white;
  font-weight: 400;
  overflow: visible;
}

.addNewFieldChooser .fieldType {
  padding: 8px 20px;
  border-bottom: 1px solid white;
  cursor: pointer;
  width: calc(100% - 40px);
  position: relative;
  height: 22px;
}

.addNewFieldChooser .fieldType[disabled] {
  color: grey !important;
}

.addNewFieldChooser .fieldType.cancel {
  color: var(--color_almost_black);
  font-weight: 500;
}

.addNewFieldChooser .fieldType:not([disabled]):hover {
  font-weight: 700;
}

.action .actionLabel {
  font-size: 16px;
}

@media screen and (max-width: 1300px) {
  .action {
    padding: 2px 8px 0px;
  }
  .action .actionLabel {
    display: none;
  }
}

</style>
