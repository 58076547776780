<template>
  <div>
    <div class="rowLabel"><input :disabled="isTemplate" type="checkbox" v-model="showDate" @change="store()"/> {{ $t('editor.inspector.date') }}<HelpOverlay :content="$t('editor.inspector.hints.date')"/></div>
    <div class="rowLabel"><input :disabled="isTemplate" type="checkbox" v-model="showTime" @change="store()"/> {{ $t('editor.inspector.time') }}<HelpOverlay :content="$t('editor.inspector.hints.time')"/></div>
    <div class="rowLabel"><input :disabled="isTemplate" type="checkbox" v-model="showReoccurrences" @change="store()"/> {{ $t('editor.inspector.repetition') }}<HelpOverlay :content="$t('editor.inspector.hints.repetition')"/></div>
    <div class="rowLabel"><input :disabled="isTemplate" type="checkbox" v-model="showDays" @change="store()"/> {{ $t('editor.inspector.days') }}<HelpOverlay :content="$t('editor.inspector.hints.days')"/></div>
    <hr v-if="showDate || showTime"/>
    <div v-if="showDate">
      <div class="rowLabel">{{ $t('editor.inspector.datePlaceholder') }}: <HelpOverlay :content="$t('editor.inspector.hints.datePlaceholder')"/></div>
      <input :disabled="isTemplate" type="text" v-model="datePlaceholder" @change="store()" @keyup="store"/>
    </div>
    <div v-if="showTime">
      <div class="rowLabel">{{ $t('editor.inspector.timePlaceholder') }}: <HelpOverlay :content="$t('editor.inspector.hints.timePlaceholder')"/></div>
      <input :disabled="isTemplate" type="text" v-model="timePlaceholder" @change="store()" @keyup="store"/>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import EditableField from '@/helpers/EditableField';
import HelpOverlay from '@/components/HelpOverlay.vue';

export default {
  name: 'DateInspectorComponent',
  props: {
    currentField: {
      type: EditableField,
      default: null,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HelpOverlay,
  },
  data() {
    return {
      config: null,
      showTime: false,
      showDate: true,
      showReoccurrences: true,
      showDays: true,
      datePlaceholder: this.$t('editor.inspector.date'),
      timePlaceholder: this.$t('editor.inspector.time'),
    };
  },
  watch: {
    currentField(newVal) {
      this.update(newVal);
    },
  },
  mounted() {
    this.update(this.currentField);
  },
  methods: {
    update(newVal) {
      this.config = newVal != null ? newVal.config : null;
      if (this.config != null) {
        const decoded = Buffer.from(this.config.special, 'base64');
        const parsed = JSON.parse(decoded);
        this.showTime = parsed.showTime;
        this.showDate = parsed.showDate;
        this.showReoccurrences = parsed.showReoccurrences;
        this.datePlaceholder = parsed.datePlaceholder;
        this.timePlaceholder = parsed.timePlaceholder;
        this.showDays = parsed.showDays;
      }
    },
    store() {
      this.config.special = Buffer.from(JSON.stringify({
        showTime: this.showTime,
        showDate: this.showDate,
        showReoccurrences: this.showReoccurrences,
        showDays: this.showDays,
        timePlaceholder: this.timePlaceholder,
        datePlaceholder: this.datePlaceholder,
      })).toString('base64');
      this.$emit('updateCanvas');
    },
  },
  computed: {
  },
};
</script>

<style scoped>

</style>
