<template>
  <div>
    <div class="rowLabel">{{ $t('editor.inspector.keyboardType') }}
      <HelpOverlay :content="$t('editor.inspector.hints.keyboardType')"/>
    </div>
    <div class="select-wrapper">
      <select :disabled="isTemplate" v-model='inputType' @change="store()">
        <option value="any">{{ $t('editor.inspector.textInput') }}</option>
        <option value="number">{{ $t('editor.inspector.numberInput') }}</option>
      </select>
    </div>
    <div class="rowLabel">
      <input :disabled="isTemplate" type="checkbox" v-model="hideInput" @change="store()"/> {{ $t('editor.inspector.hideInput') }}
      <HelpOverlay :content="$t('editor.inspector.hints.hideInput')"/>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import EditableField from '@/helpers/EditableField';
import HelpOverlay from '@/components/HelpOverlay.vue';

export default {
  name: 'TextFieldInspectorComponent',
  props: {
    currentField: {
      type: EditableField,
      default: null,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HelpOverlay,
  },
  data() {
    return {
      config: null,
      inputType: false,
      hideInput: true,
    };
  },
  watch: {
    currentField(newVal) {
      this.update(newVal);
    },
  },
  mounted() {
    this.update(this.currentField);
  },
  methods: {
    update(newVal) {
      this.config = newVal != null ? newVal.config : null;
      if (this.config != null) {
        const decoded = Buffer.from(this.config.special, 'base64');
        const parsed = JSON.parse(decoded);
        this.inputType = (parsed.inputType !== undefined && parsed.inputType !== null) ? parsed.inputType : 'any';
        this.hideInput = (parsed.hideInput !== undefined && parsed.hideInput !== null) ? parsed.hideInput : false;
      }
    },
    store() {
      this.config.special = Buffer.from(JSON.stringify({
        inputType: this.inputType,
        hideInput: this.hideInput,
      })).toString('base64');
      this.$emit('updateCanvas');
    },
  },
  computed: {
  },
};
</script>

<style scoped>

</style>
