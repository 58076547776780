<template>
  <div>
    <canvas ref="rulerCanvas"/>
  </div>
</template>
<script>
/* eslint-disable no-console */
export default {
  name: 'RulerComponent',
  components: {
  },
  props: {
    orientation: {
      type: String,
      default: 'h',
    },
    pageSize: {
      type: Object,
      default: () => ({ width: 640, height: 960 }),
    },
    mousePos: {
      type: Object,
      default: () => ({ x: 0, y: 0 }),
    },
    marked: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      canvas: null,
      context: null,
      rulerSize: 20,
    };
  },
  mounted() {
    this.initCanvas();
    this.updateRuler();
  },
  watch: {
    mousePos(newVal) {
      this.updateRuler(newVal, this.marked);
    },
    marked(newVal) {
      this.updateRuler(this.mousePos, newVal);
    },
  },
  methods: {
    createHiPPICanvas(canvasToEdit, w, h) {
      const ratio = window.devicePixelRatio;
      const canvas = canvasToEdit;
      let ctx = null;
      canvas.width = w * ratio;
      canvas.height = h * ratio;
      canvas.style.width = `${w}px`;
      canvas.style.height = `${h}px`;
      ctx = canvas.getContext('2d');
      ctx.scale(ratio, ratio);
      return ctx;
    },
    initCanvas() {
      this.canvas = this.$refs.rulerCanvas;
      if (this.orientation === 'h') {
        this.context = this.createHiPPICanvas(this.canvas, this.pageSize.width + 2, this.rulerSize);
      } else {
        this.context = this.createHiPPICanvas(this.canvas, this.rulerSize, this.pageSize.height);
      }
    },
    updateRuler(currentMousePosition, marked) {
      const { width, height } = this.canvas;
      const ctx = this.context;
      ctx.clearRect(0, 0, width, height);

      if (marked != null && marked.length > 0) {
        ctx.fillStyle = '#3ba67a66';
        if (this.orientation === 'h') {
          let start = marked[0].config.x;
          let end = marked[0].config.x + marked[0].config.width;
          for (let i = 0; i < marked.length; i += 1) {
            start = Math.min(start, marked[i].config.x);
            end = Math.max(end, marked[i].config.x + marked[0].config.width);
          }
          ctx.fillRect(start, 0, end - start, this.rulerSize);
        } else {
          let start = marked[0].config.y;
          let end = marked[0].config.y + marked[0].config.height;
          for (let i = 0; i < marked.length; i += 1) {
            start = Math.min(start, marked[i].config.y);
            end = Math.max(end, marked[i].config.y + marked[0].config.height);
          }
          ctx.fillRect(0, start, this.rulerSize, end - start);
        }
      }

      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#aaa';
      if (this.orientation === 'h') {
        let xPos = 0;
        while (xPos < width) {
          ctx.moveTo(xPos, xPos % 50 === 0 ? 0 : this.rulerSize / 2);
          ctx.lineTo(xPos, this.rulerSize);
          xPos += 10;
        }
      } else {
        let yPos = 0;
        while (yPos < height) {
          ctx.moveTo(yPos % 50 === 0 ? 0 : this.rulerSize / 2, yPos);
          ctx.lineTo(this.rulerSize, yPos);
          yPos += 10;
        }
      }
      ctx.stroke();
      ctx.strokeStyle = '#000';
      ctx.lineWidth = 2;
      ctx.beginPath();
      if (currentMousePosition != null) {
        if (this.orientation === 'h') {
          ctx.moveTo(currentMousePosition.x, 0);
          ctx.lineTo(currentMousePosition.x, this.rulerSize);
        } else {
          ctx.moveTo(0, currentMousePosition.y);
          ctx.lineTo(this.rulerSize, currentMousePosition.y);
        }
      }
      ctx.stroke();
    },
  },
};
</script>
<style scoped>

</style>
