<template>
  <div class="date mobileField" v-if="dateConfig != null">
    <div class="row" v-if="dateConfig.showDate || dateConfig.showTime">
      <div class="col" v-if="dateConfig.showDate">
        {{ dateConfig.datePlaceholder }}
      </div>
      <div class="col" v-if="dateConfig.showTime">
        {{ dateConfig.timePlaceholder }}
      </div>
    </div>
    <div class="row" v-if="dateConfig.showReoccurrences">
      Wiederholung
    </div>
    <div class="row" v-if="dateConfig.showDays">
      Tage
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

export default {
  name: 'DateViewComponent',
  props: {
    config: {
      type: Object,
      default: null,
    },
  },
  components: {
  },
  data() {
    return {
      dateConfig: {},
    };
  },
  watch: {
  },
  mounted() {
    if (this.config != null) this.init(this.config);
  },
  methods: {
    init() {
      try {
        const decoded = Buffer.from(this.config.special, 'base64');
        this.dateConfig = JSON.parse(decoded);
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
  },
};
</script>

<style scoped>
.date.mobileField {
  margin-top: 8px;
}
</style>
