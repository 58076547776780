<template>
  <div>
    <div class="rowLabel">{{ $t('editor.inspector.action') }}
      <HelpOverlay :content="$t('editor.inspector.hints.action')"/>
    </div>
    <div class="select-wrapper">
      <select :disabled="isTemplate" v-model='action' @change="store()">
        <option value="-1"></option>
        <option v-for="a in actionOptions" :key="a.type" :value="a.type">{{a.name}}</option>
      </select><br/>
    </div>
    <div v-if="action == 4">
      {{ $t('editor.inspector.function') }}: <br/>
      <input :disabled="isTemplate" type="text" v-model="functionName" @change="store()" @keyup="store()"/>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import EditableField from '@/helpers/EditableField';
import Constants from '@/helpers/Constants';
import HelpOverlay from '@/components/HelpOverlay.vue';

export default {
  name: 'ActionInspectorComponent',
  props: {
    currentField: {
      type: EditableField,
      default: null,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HelpOverlay,
  },
  data() {
    return {
      config: null,
      action: -1,
      functionName: '',
      actionOptions: Constants.ACTIVE_ACTION_TYPES.map((c) => ({
        type: c,
        name: this.$t(`editor.actions.${Constants.ACTION_TYPE_NAMES[c]}`),
      })),
    };
  },
  watch: {
    currentField(newVal) {
      this.update(newVal);
    },
  },
  mounted() {
    this.update(this.currentField);
  },
  methods: {
    update(newVal) {
      this.config = newVal != null ? newVal.config : null;
      if (this.config != null) {
        const decoded = Buffer.from(this.config.special, 'base64');
        const parsed = JSON.parse(decoded);
        this.action = parsed.type;
        this.functionName = parsed.functionName;
      }
    },
    store() {
      // console.log('STORE', { type: this.action, ...(this.action === 4 ? { functionName: this.functionName } : {}) });
      this.config.special = Buffer.from(JSON.stringify({ type: this.action, ...(this.action === 4 ? { functionName: this.functionName } : {}) })).toString('base64');
    },
  },
  computed: {
  },
};
</script>

<style scoped>

</style>
