/* eslint-disable no-console,no-alert,no-underscore-dangle,no-bitwise */
const Constants = require('./Constants');

module.exports = {
  getListValueForField: (values, fieldName, lineSeparator = '<br/>') => {
    const val = values[fieldName];
    if (val == null || val === '[]' || val === '') {
      return '';
    }
    try {
      return JSON.parse(val).join(lineSeparator);
    } catch (err) {
      console.log(err);
    }
    return val;
  },
  getTableValueForField: (values, fieldName, tableConfig, lineSeparator, forHTML = true) => {
    const val = values[fieldName];
    if (val == null || val === '[]' || val === '') {
      return '';
    }
    const rows = JSON.parse(val);
    try {
      let res = '';
      if (forHTML) {
        res += '<table class="customTable"><thead>';
        tableConfig.columns.forEach((c) => {
          res += `<th width='${c.width}'>${c.name}</th>`;
        });
        res += '</thead>';

        rows.forEach((row) => {
          res += '<tr>';
          tableConfig.columns.forEach((c) => {
            res += `<td>${row[c.id] ?? ''}</th>`;
          });
          res += '</tr>';
        });

        res += '</table>';
      } else {
        let idx = 0;
        tableConfig.columns.forEach((c) => {
          res += (idx > 0 ? '|' : '');
          res += `${c.name}`;
          idx += 1;
        });
        res += lineSeparator;
        rows.forEach((row) => {
          res += '';
          idx = 0;
          tableConfig.columns.forEach((c) => {
            res += (idx > 0 ? '|' : '');
            res += `${row[c.id] ?? ''}`;
            idx += 1;
          });
          res += lineSeparator;
        });
      }
      return res;
    } catch (err) {
      console.log(err);
    }
    return val;
  },
  getDateValueForField: (values, fieldName, i18n, lineSeparator, daySeparator = ', ') => {
    const val = values[fieldName];
    if (val == null) return null;
    let value = '';
    try {
      const valueDict = JSON.parse(Buffer.from(val, 'base64'));
      if (valueDict.date != null) {
        value = `${valueDict.date} `;
      }
      if (valueDict.time != null) {
        value += `${valueDict.time}`;
      }
      value = value.trim();
      if (valueDict.selectedReoccurrence != null) {
        if (value.length > 0) value += lineSeparator;
        value += i18n(`editor.repetitionOptions.${Constants.REOCCURENCE_TYPES[valueDict.selectedReoccurrence]}`);
      }
      value = value.trim();
      if (valueDict.daysBitMask != null && parseInt(valueDict.daysBitMask, 10) > 0) {
        if (value.length > 0) value += lineSeparator;
        let days = '';
        const daysBitMask = parseInt(valueDict.daysBitMask, 10);
        if (daysBitMask & 1) days += `${(days === '' ? '' : daySeparator)}${i18n('dayShortNames.mo')}`;
        if (daysBitMask & 2) days += `${(days === '' ? '' : daySeparator)}${i18n('dayShortNames.tu')}`;
        if (daysBitMask & 4) days += `${(days === '' ? '' : daySeparator)}${i18n('dayShortNames.we')}`;
        if (daysBitMask & 8) days += `${(days === '' ? '' : daySeparator)}${i18n('dayShortNames.th')}`;
        if (daysBitMask & 16) days += `${(days === '' ? '' : daySeparator)}${i18n('dayShortNames.fr')}`;
        if (daysBitMask & 32) days += `${(days === '' ? '' : daySeparator)}${i18n('dayShortNames.sa')}`;
        if (daysBitMask & 64) days += `${(days === '' ? '' : daySeparator)}${i18n('dayShortNames.su')}`;
        value += `${days}`;
      }
    } catch (e) {
      console.error(e);
    }
    return value;
  },
  getGPSValueForField(fieldConfig, values, i18n) {
    let val = values[fieldConfig.name];
    if (val != null) {
      val = Buffer.from(val, 'base64').toString();
      try {
        const parts = val.split(',');
        if (parts.length === 2) {
          if (i18n != null) {
            return `${i18n('detail.latitude')}: ${parts[0]}, ${i18n('detail.longitude')}: ${parts[1]}`;
          }
          return `[${parts[0]}, ${parts[1]}]`;
        }
      } catch (e) {
        return null;
      }
    }
    return null;
  },
  getMapLink(fieldConfig, values) {
    let val = values[fieldConfig.name];
    if (val != null) {
      val = Buffer.from(val, 'base64').toString();
      try {
        const parts = val.split(',');
        if (parts.length === 2) {
          return `https://www.openstreetmap.org/?mlat=${parts[0]}&mlon=${parts[1]}`;
        }
      } catch (e) {
        console.error(e);
        return null;
      }
    }
    return null;
  },
  getActionType(fieldConfig) {
    if (fieldConfig != null) {
      const decoded = Buffer.from(fieldConfig.special, 'base64');
      const parsed = JSON.parse(decoded);
      return parsed.type;
    }
    return null;
  },
};
