<template>
  <CustomModal v-model="showModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{title}}</template>
    <div>{{text}}</div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';

export default {
  name: 'ConfirmModal',
  props: ['title', 'text'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showModal: false,
      resolve: null,
      reject: null,
      promise: null,
    };
  },
  methods: {
    async show() {
      this.showModal = true;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      return this.promise;
    },
    hide() {
      this.showModal = false;
    },
    confirm() {
      this.resolve(true);
      this.hide();
    },
    cancel() {
      this.resolve(false);
      this.hide();
    },
  },
};
</script>

<style scoped>

</style>
