<template>
  <div>
    <div class="sectionTitle">{{ $t('editor.inspector.columns') }}
      <HelpOverlay :content="$t('editor.inspector.hints.columns')"/>
    </div>
    <div v-if="config != null" class="valueList">
      <div class="valueRow" v-for="(val, idx) in tableConfig.columns" :key="idx">
        <div class="columnInfo">
          <div class="columnName">{{val.name}}</div>
          <div class="columnType">{{ fieldType(val) }}, {{val.width}} %</div>
        </div>
        <div class="actions">
          <span v-if="!isTemplate" class="action" :class="{locked: val.locked, inactive: lockCount() > tableConfig.columns.length - 2}" @click="lockColumn(idx)"><i class="fas fa-lock"></i></span>
          <span v-if="!isTemplate" class="action" @click="editColumn(idx)"><i class="fas fa-pen"></i></span>
          <span v-if="!isTemplate" class="action" :class="{inactive: tableConfig.columns.length < 2}"  @click="removeValue(idx)"><i class="fas fa-trash"></i></span>
          <span v-if="!isTemplate" class="action" :class="{inactive: idx == 0}" @click="moveColumnUp(idx)"><i class="fas fa-angle-up"/></span>
          <span v-if="!isTemplate" class="action" :class="{inactive: idx == tableConfig.columns.length-1}" @click="moveColumnDown(idx)">
            <i class="fas fa-angle-down"></i>
          </span>
        </div>
      </div>
    </div>
    <!--<input v-if="!isTemplate" type="text" class="hasPicker" v-model='newValue' :placeholder="$t('editor.inspector.placeholder.newColumn')" @keyup.enter="addColumn()"/>-->
    <div v-if="!isTemplate" class="addButton" @click="addColumn()"><i class="fas fa-plus-square"></i></div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import EditableField from '@/helpers/EditableField';
import Constants from '@/helpers/Constants';
import HelpOverlay from '@/components/HelpOverlay.vue';

const uuidv4 = require('uuid').v4;

export default {
  name: 'TableInspectorComponent',
  props: {
    currentField: {
      type: EditableField,
      default: null,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HelpOverlay,
  },
  data() {
    return {
      config: null,
      tableConfig: { columns: [] },
      newValue: '',
      fieldTypes: Constants.FIELD_TYPES,
      fieldTypeNames: Constants.FIELD_NAMES,
    };
  },
  watch: {
    currentField(newVal) {
      this.update(newVal);
    },
  },
  mounted() {
    this.update(this.currentField);
  },
  methods: {
    fieldType(columnConfig) {
      return this.$t(`editor.types.${Constants.FIELD_TYPE_NAMES[columnConfig.type]}`);
    },
    update(newVal) {
      try {
        this.config = newVal != null ? newVal.config : null;
        if (this.config != null) {
          const decoded = Buffer.from(this.config.special, 'base64');
          this.tableConfig = JSON.parse(decoded);
        }
      } catch (e) {
        console.error(e);
      }
    },
    addColumn() {
      if (this.tableConfig.columns == null || this.tableConfig.columns.constructor !== Array) this.tableConfig.columns = [];
      // if (this.newValue.trim().length > 0) {
      this.editColumn(-1, this.newValue);
      this.newValue = '';
      this.store();
      // }
    },
    lockCount() {
      return this.tableConfig.columns.filter((c) => c.locked).length;
    },
    lockColumn(idx) {
      if (this.tableConfig.columns.length === 1) {
        this.tableConfig.columns[0].locked = false;
        return;
      }
      const col = this.tableConfig.columns[idx];
      if (col.locked || this.lockCount() < this.tableConfig.columns.length - 1) {
        col.locked = !col.locked;
      }
    },
    async editColumn(idx, newName) {
      // eslint-disable-next-line no-alert
      const defaultSize = 20;
      const toEdit = idx > -1 ? this.tableConfig.columns[idx] : {
        id: uuidv4(),
        name: newName,
        type: Constants.FIELD_TYPES.textfield,
        width: defaultSize,
        inputType: 'any',
      };
      const lockWidth = this.lockCount() > this.tableConfig.columns.length - 2 && idx >= 0;
      this.$emit('showColumnEditDialog', toEdit, lockWidth, (updated) => {
        if (updated != null) {
          if (idx > -1) {
            this.tableConfig.columns[idx] = updated;
          } else {
            this.tableConfig.columns.push(updated);
          }
          this.redistributeColumns(updated);
        }
        this.store();
      });
    },
    redistributeColumns(updated) {
      if (this.tableConfig.columns.length === 1) {
        this.tableConfig.columns[0].locked = false;
        this.tableConfig.columns[0].width = 100;
        return;
      }

      const distributableColumns = this.tableConfig.columns.filter((c) => c !== updated && !c.locked);
      const fixColumns = this.tableConfig.columns.filter((c) => c === updated || c.locked);
      let cummSize = 0;
      let fixedSize = 0;
      let distributableSize = 0;

      this.tableConfig.columns.forEach((c) => {
        cummSize += c.width;
      });

      distributableColumns.forEach((c) => {
        distributableSize += c.width;
      });

      fixColumns.forEach((c) => {
        fixedSize += c.width;
      });

      if (cummSize !== 100) {
        distributableColumns.forEach((c) => {
          const col = c;
          col.width = Math.round((col.width / distributableSize) * (100 - fixedSize));
        });
      }
    },
    moveColumnUp(idx) {
      if (idx > 0) {
        const tmp = this.tableConfig.columns[idx - 1];
        this.tableConfig.columns[idx - 1] = this.tableConfig.columns[idx];
        this.tableConfig.columns[idx] = tmp;
        this.store();
      }
    },
    moveColumnDown(idx) {
      if (idx < this.tableConfig.columns.length - 1) {
        const tmp = this.tableConfig.columns[idx + 1];
        this.tableConfig.columns[idx + 1] = this.tableConfig.columns[idx];
        this.tableConfig.columns[idx] = tmp;
        this.store();
      }
    },
    removeValue(idx) {
      if (this.tableConfig.columns.length < 2) return;
      const colToRemove = this.tableConfig.columns.splice(idx, 1)[0];
      this.redistributeColumns(colToRemove);
      this.store();
    },
    store() {
      this.config.special = Buffer.from(JSON.stringify(this.tableConfig)).toString('base64');
      this.$emit('updateCanvas');
    },
  },
  computed: {
  },
};
</script>

<style scoped>
.columnInfo {
  flex: 0 auto;
  overflow-x: hidden;
}

.columnInfo .columnName {
  text-overflow: ellipsis;
  font-weight: 500;
  overflow-x: hidden;
}

.columnInfo .columnType {
  font-size: 0.8em;
}
.actions {
  flex: 0 0;
  display: flex;
  white-space: nowrap;
}
.action {
  background: var(--color_brown);
  color: white;
  padding: 4px;
  font-size: 0.8em;
  margin-left: 4px;
  cursor: pointer;
  border: none;
  justify-items: flex-end;
  flex: 0 0;
}

.action:hover {
  background: var(--color_orange);
}

.action.inactive {
  background: var(--color_light_brown);
  cursor: default;
}
.action.locked {
  background: var(--color_orange);
  cursor: default;
}

.valueList {
  overflow-y: auto;
}
.valueRow {
  display: flex;
  max-width: 100%;
  margin: 8px 0px;
  border-bottom: 0.5px solid #444;
  align-items: center;
  justify-content: space-between;

}

.addButton {
  float: none !important;
}
</style>
