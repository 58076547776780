<template>
  <div class="helpPopUp">
    <Popper :content="content" :hover="true" :arrow="true" :offsetDistance="6" :placement="placement">
      <i class="far fa-question-circle"/>
    </Popper>
  </div>
</template>
<script>
/* eslint-disable no-console */

export default {
  name: 'HelpOverlay',
  props: {
    content: String,
    placement: {
      type: String,
      default: 'left',
    },
  },
};
</script>

<style scoped>
.helpPopUp {
  position: absolute;
  right: 8px;
  top: 50%;
  z-index: 101;
  overflow: visible;
  transform: translateY(-50%);
  height: 20px;
  color: white;
}

.inspector .helpPopUp {
  right: 2px;
  color: var(--color_light_gray);
}

.popper, ::v-deep(.popper) {
  font-weight: normal !important;
  max-width: 300px;
  width: 300px;
  text-align: left;
  line-height: 18px;
  font-size: 14px;

}

</style>
