/* eslint-disable no-console */
import EditableField from './EditableField';
import Constants from './Constants';

const defaultTableConfig = {
  showHead: true,
  columns: [
    {
      id: 'sample_1',
      type: Constants.FIELD_TYPES.textfield,
      name: 'Pos.',
      width: 25.0,
      inputType: 'number',
    },
    {
      id: 'sample_2',
      type: Constants.FIELD_TYPES.dropdown,
      name: 'Material',
      width: 25.0,
      values: ['Holz', 'Alu'],
    },
    {
      id: 'sample_3',
      type: Constants.FIELD_TYPES.textfield,
      name: 'Bezeichnung',
      width: 50.0,
      inputType: 'any',
    },
  ],
};

class TableField extends EditableField {
  constructor(fieldConfig, t) {
    super(fieldConfig, t);
    const fc = fieldConfig;
    console.log(fc.special);
    if (fc.special == null || fc.special.length < 5) {
      fc.special = Buffer.from(JSON.stringify(defaultTableConfig)).toString('base64');
    }
  }

  renderInContext(context, mouseOver) {
    const ctx = context;
    ctx.lineWidth = mouseOver ? 2 : 1;
    ctx.strokeStyle = mouseOver ? '#FF8888' : '#888';
    ctx.fillStyle = '#000';

    const font = `${this.config.fontsize}px ${this.config.fontname}`;

    let fontColor = Constants.convertColor(this.config.fontcolor != null ? this.config.fontcolor : '0#0#0#1');
    if (this.doNotShowOnTablet()) {
      fontColor = Constants.convertAndMergeColor(this.config.fontcolor != null ? this.config.fontcolor : '0#0#0#1', '255#255#255');
    }

    let tableConfig = {};

    try {
      if (this.config.special != null) {
        const decoded = Buffer.from(this.config.special, 'base64');
        if (decoded.toString() !== '{}') {
          const parsed = JSON.parse(decoded);
          tableConfig = parsed;
        }
      }
    } catch (e) {
      console.error(e);
    }

    const textMetrics = this.getTextMetrics(ctx, font, '1');
    const lineHeight = textMetrics.descent + textMetrics.textHeight;
    let yPos = 4;
    let i = 1;
    // const leftPadding = 4;
    while (yPos + lineHeight < this.config.height) {
      const yP = yPos;
      const lH = lineHeight;
      const idx = i;
      let left = this.config.x;
      tableConfig.columns.forEach((column) => {
        const width = this.config.width * (column.width / 100);
        this.renderTextInBox(
          ctx,
          font,
          idx === 1 ? column.name : 'Beispieltext',
          true,
          idx === 1 ? '#f49954' : fontColor,
          parseInt(left, 10) + this.fieldPadding,
          parseInt(this.config.y + yP, 10),
          parseInt(width, 10),
          parseInt(lH, 10),
          null,
        );
        left += width;
      });

      ctx.strokeStyle = '#888';
      ctx.lineWidth = 1;
      ctx.beginPath();
      yPos += lineHeight + 2;
      ctx.moveTo(parseInt(this.config.x, 10), parseInt(this.config.y + yPos, 10));
      ctx.lineTo(parseInt(this.config.x + this.config.width, 10), parseInt(this.config.y + yPos, 10));
      ctx.stroke();
      yPos += 2;
      i += 1;
    }

    super.renderBorderInContext(context, mouseOver);
  }
}

export default TableField;
